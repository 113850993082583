// @flow

import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '../link';
import STYLES from './PrimaryButton.module.scss';

type LinkInfo = {
  newTab?: boolean,
  linkUrl: string,
  linkTag: string,
  linkDescription: string,
  onClick?: () => void,
}

type Props = {
    title: string,
    loading?: boolean,
    onClick?: () => void,
    link?: LinkInfo,
    type?: 'default' | 'secondary',
}

const buildLink = (link: LinkInfo, children: React.Node) => {
  const {
    newTab = false, linkUrl, linkTag, linkDescription, onClick,
  } = link;
  return (newTab ? (
    <Link
      href={linkUrl || ''}
      tag={linkTag}
      description={linkDescription}
      onClick={onClick}
      newTab
    >
      {children}
    </Link>
  )
    : (<RouterLink className={STYLES.Link} to={linkUrl || ''} onClick={onClick}>{children}</RouterLink>));
};

const buildButton = (title: string, styles: Array<string>, onClick?: () => void) => {
  const onClickProps = onClick ? {
    onClick,
    onKeyPress: onClick,
    tabIndex: '0',
  } : {};

  const divProps: any = {
    className: styles.join(' '),
    ...onClickProps,
  };
  return React.createElement('div', divProps, title);
};

const PrimaryButton = (props: Props) => {
  const {
    title, loading, onClick, link, type = 'default',
  } = props;
  const styles = [];
  styles.push(STYLES.Button);
  if (type === 'secondary') {
    styles.push(STYLES.Secondary);
  }
  if (loading) {
    styles.push(STYLES.Loading);
  } else if (!link && !onClick) {
    styles.push(STYLES.Disabled);
  }

  const divButton = buildButton(title, styles, onClick);

  return link ? buildLink(link, divButton) : divButton;
};

PrimaryButton.defaultProps = {
  loading: false,
  onClick: undefined,
  type: 'default',
};

export default PrimaryButton;
