/* eslint-disable max-len */
// @flow
import { Cookies } from 'react-cookie-consent';
import { fbInit, fbSetConsent, fbEvent } from './analytics/FBPixel';
import { branchInit, branchConsent, branchEvent } from './analytics/Branch';
import { gtagInit, gtagEvent, gtagPageChanged } from './analytics/Gtag';
import config from '../config';
import { CONSENT_COOKIE_NAME } from '../constants';
import { locationQueryParams } from './queryParams';
import { doRedirect } from './navigation';

const amplitude = typeof window !== 'undefined' ? require('amplitude-js') : undefined;

type FBSearchParams = {|
  search_string: string,
  content_category: string,
|}

export type UserActionParams = {|
  name: string,
  evtParams: any,
|}

export type NavigationParams = {|
  name: string,
  source?: string,
  destination?: string,
|}

export type TimingParams = {|
  category: string,
  variable: string,
  value: number,
|};

export type OutboundParams = {|
  category?: string,
  tag: string,
  description: string,
  url: string,
  newTab?: boolean,
|};

export type SearchParams = {|
  fullAddress: string,
  city: string,
|}

export type RedirectParams = {|
  option: string,
  city: string,
  restaurant: string,
  url: string,
  storeId?: string,
  newTab?: boolean,
|}

const apEvent = (eventName: string, params: any = {}) => {
  if (!amplitude) return;
  amplitude.getInstance().logEvent(eventName, params);
};

const commonEvent = (eventName: string, params: any = {}) => {
  const queryParams = locationQueryParams() || {};
  const utmParams = {};
  Object.keys(queryParams)
    .filter((p) => p.indexOf('utm_') !== -1)
    .forEach((key) => {
      utmParams[key] = queryParams[key];
    });

  const finalParams = {
    ...utmParams,
  };

  // Clean undefined parameters
  Object.keys(params)
    .filter((k) => params[k] !== undefined)
    .forEach((k) => { finalParams[k] = params[k]; });

  gtagEvent(eventName, finalParams);
  fbEvent(eventName, finalParams);
  apEvent(eventName, finalParams);
  branchEvent(`web-${eventName}`, finalParams);
};

const fbEventSearch = (params: FBSearchParams) => {
  if (typeof window.fbq !== 'function') { return; }
  window.fbq('track', 'Search', params);
};

const pageView = (page: string) => {
  gtagPageChanged();
  commonEvent(`view-${page}`);
};

const timing = (params: TimingParams) => {
  const { category, variable, value } = params;
  commonEvent(`timing-${category}`, {
    variable,
    value,
  });
};

const navigation = (params: NavigationParams) => {
  const { name, source, destination } = params;
  commonEvent(`navigate-${name}`, { source, destination });
};

const userAction = (params: UserActionParams) => {
  const {
    name, evtParams = {},
  } = params;
  commonEvent(name, evtParams);
};

const outboundLink = (params: OutboundParams) => {
  const {
    category = 'outbound', tag, description, url, newTab = false,
  } = params;

  commonEvent(category, {
    tag, description, url, newTab,
  });

  doRedirect(url, newTab);
};

const searchResultsStart = () => {
  commonEvent('search-results-start');
};

const searchResults = (params: SearchParams) => {
  const { fullAddress, city } = params;
  commonEvent('search-results-finished', params);
  fbEventSearch({
    search_string: fullAddress,
    content_category: city,
  });
};

const redirect = (params: RedirectParams) => {
  const {
    option, city, restaurant, url, storeId, newTab = true,
  } = params;

  const properties = {
    delivery_option: option,
    restaurant,
    city,
    url,
    store_id: storeId,
  };
  commonEvent('redirect', properties);

  // Redirect to install the app
  doRedirect('https://delitbee.app.link/jbyxCdUWTtb', newTab);
};

const setConsent = (consent: boolean = true, logEvent: boolean = true) => {
  fbSetConsent(consent);
  branchConsent(consent);
  if (consent) {
    if (logEvent) {
      commonEvent('accept-cookies');
    }
    if (amplitude) {
      amplitude.getInstance().setOptOut(false);
    }
  } else {
    if (logEvent) {
      commonEvent('reject-cookies');
    }
    if (amplitude) {
      amplitude.getInstance().setOptOut(true);
    }
  }
};

const initialize = () => {
  gtagInit(config.gAnalyticsId, config.gTagId);
  branchInit(config.branchKey);
  if (amplitude) {
    amplitude.getInstance().init(config.amplitudeKey);
  }
  fbInit(config.fbPixelId);
  setConsent(Cookies.get(CONSENT_COOKIE_NAME), false);
};

export default {
  initialize,
  pageView,
  timing,
  searchResults,
  searchResultsStart,
  redirect,
  outboundLink,
  navigation,
  userAction,
  setConsent,
};
