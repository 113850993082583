// @flow
import type { Config } from '../types';

const config: Config = {
  apiHost: 'https://api.delitbee.com/',
  gAnalyticsId: 'G-1TBRMML12M',
  gTagId: 'G-1TBRMML12M',
  mixpanelId: '2853efb618e0ab15d8ddbe5a88946535',
  amplitudeKey: '1ca6d1807a8a01662d2c26f4e5f254b9',
  fbPixelId: '696773927548274',
  branchKey: 'key_live_gaJ8QZca8B45trtsvj0rCfdgFsnAtgVq',
  googleApiKey: 'AIzaSyDKGAuIeRpMyzHrUW9CNWp0AB5NrnGrwco',
};

export default config;
