/* eslint-disable max-len */
// @flow

import React from 'react';

import { bindAutocomplete, buildLinkUrl } from '../../services/Location';
import CookieBanner from '../../components/CookieBanner';
import Analytics from '../../services/Analytics';
import Search from '../../components/search';
import { setBodyClass } from '../../utils/bodyClasses';
import type { ResultsUrl, SearchLocation } from '../../types';

type Props = {
  locale: string,
  beta?: boolean,
};

class EmbeddedSearch extends React.Component<Props> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    beta: false,
  };

  componentDidMount() {
    Analytics.pageView('embedded-search');
    setBodyClass('embedded-body');
  }

  render() {
    const { beta = false, locale: intLocale } = this.props;

    const buildLinkUrlWrapped = async (
      searchLocation?: SearchLocation,
      locale: string): Promise<ResultsUrl> => buildLinkUrl(searchLocation, locale, beta);

    const onSearchClick = () => {
      Analytics.userAction({
        name: 'search-click',
        evtParams: { location: 'embedded-search' },
      });
    };

    return (
      <>
        <Search locale={intLocale} bindAutocomplete={bindAutocomplete} buildLinkUrl={buildLinkUrlWrapped} onClick={onSearchClick} newTab />
        <CookieBanner locale={intLocale} />
      </>
    );
  }
}

export default EmbeddedSearch;
