// @flow

import React from 'react';
import Card from '../card';
import { getLocalized } from '../../services/strings/Localisation';
import closeIcon from '../../resources/ic-close.png';
import searchIcon from '../../resources/ic-search.svg';
import STYLES from './ResultsHeader.module.scss';

type Props = {
    locale: string,
    text: string,
    onSearch: string => void,
}

const ResultsHeader = (props: Props) => {
  const { text, onSearch, locale } = props;

  return (
    <Card noPadding>
      <div className={STYLES.Container}>
        <img className={STYLES.Search} src={searchIcon} alt="Search Icon" />
        <input
          type="text"
          onChange={(e) => onSearch(e.target.value)}
          placeholder={getLocalized(locale, 'Results search placeholder')}
          value={text}
        />
        {text
            && (
            <button className={STYLES.Button} type="button" onClick={() => onSearch('')}>
              <img className={STYLES.Clear} src={closeIcon} alt="clear text" />
            </button>
            )}
      </div>
    </Card>
  );
};

export default ResultsHeader;
