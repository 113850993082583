// @flow

import React from 'react';
import STYLES from './Background.module.scss';

type Props = {
  onlyDesktop?: boolean,
}

const Background = (props: Props) => {
  const { onlyDesktop } = props;
  return (
    <div className={`${STYLES.Container} ${onlyDesktop ? STYLES.Desktop : ''}`}>
      <img className={STYLES.Left} alt="Background left" src="https://img.delitbee.com/media/img-bg-left.jpg" />
      <img className={STYLES.Right} alt="Background right" src="https://img.delitbee.com/media/img-bg-right.jpg" />
    </div>
  );
};

Background.defaultProps = {
  onlyDesktop: false,
};

export default Background;
