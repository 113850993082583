// @flow

import React from 'react';
import type { RouterHistory, Match } from 'react-router-dom';

import { bindAutocomplete, buildLinkUrl } from '../../services/Location';
import { buildHelmet } from '../../services/MetaUtils';
import Hubspot from '../../services/Hubspot';
import Analytics from '../../services/Analytics';
import CookieBanner from '../../components/CookieBanner';
import Logo from '../../components/logo';
import Footer from '../../components/footer';
import Background from '../../components/background';
import Search from '../../components/search';
import Claim from '../../components/claim';
import { clearBodyClasses } from '../../utils/bodyClasses';
import STYLES from './Home.module.scss';

import type { SearchLocation, ResultsUrl } from '../../types';

type Props = {
  locale: string,
  searchStyle: 'hidden' | 'normal' | 'beta',
  history: RouterHistory,
  match: Match,
};

class Main extends React.Component<Props> {
  componentDidMount() {
    const { searchStyle = 'normal', match, history } = this.props;
    Analytics.pageView(searchStyle === 'beta' ? 'home-beta' : 'home');
    clearBodyClasses();

    if (!match.isExact) {
      history.replace(match.url);
    }
    Hubspot.load();
  }

  render() {
    const { searchStyle, locale: intLocale } = this.props;

    const buildLinkUrlWrapped = async (
      searchLocation?: SearchLocation,
      locale: string): Promise<ResultsUrl> => buildLinkUrl(searchLocation, locale, searchStyle === 'beta');

    const onSearchClick = () => {
      Analytics.userAction({
        name: 'search-click',
        evtParams: { location: 'home' },
      });
    };

    return (
      <>
        {buildHelmet({
          page: 'home',
          locale: intLocale,
        })}
        <div className={STYLES.Container}>
          <Logo />
          { searchStyle !== 'hidden'
          && (
          <Search
            locale={intLocale}
            bindAutocomplete={bindAutocomplete}
            buildLinkUrl={buildLinkUrlWrapped}
            onClick={onSearchClick}
          />
          )}
          <Claim locale={intLocale} />
        </div>
        <Footer locale={intLocale} />
        <Background />
        <CookieBanner locale={intLocale} />
      </>
    );
  }
}

export default Main;
