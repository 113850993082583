// @flow

export const parseQueryParams = (query: string): any => {
  const result = {};
  query.replace('?', '').split('&').forEach((part) => {
    const item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
};

export const locationQueryParams = (): any => parseQueryParams(window.location.search);

export const locationSearch = (): string => {
  if (window.location && window.location.search) {
    return window.location.search.replace('?', '');
  }
  return '';
};
