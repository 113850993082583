// @flow

import React from 'react';
import STYLES from './Title.module.scss';

type TitleType = 'h1' | 'h2' | 'h3';

type Props = {
    text: string,
    type: TitleType,
    dark?: boolean,
}

const Title = (props: Props) => {
  const { text, type, dark } = props;
  switch (type) {
    case 'h1':
      return (<h1 className={STYLES.H1}>{text}</h1>);
    case 'h2':
      return (<h2 className={STYLES.H2}>{text}</h2>);
    case 'h3':
      return (<h3 className={`${STYLES.H3} ${dark ? STYLES.dark : STYLES.light}`}>{text}</h3>);
    default:
      return null;
  }
};

Title.defaultProps = {
  dark: false,
};

export default Title;
