/* eslint-disable no-underscore-dangle */
// @flow

export const extractServerProps = (defaults?: any = {}) => {
  if (typeof window === 'undefined') {
    return defaults;
  }
  const serverSideParams = window.__dltbInternal;
  if (serverSideParams) {
    delete window.__dltbInternal;
  }
  const element = document.getElementById('server-params');
  if (element) {
    element.remove();
  }
  return {
    ...defaults || {},
    ...serverSideParams || {},
  };
};

export const dummy = () => {};
