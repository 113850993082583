// @flow

import React, { useEffect } from 'react';
import Analytics from '../../services/Analytics';
import { filterDeliveries } from '../../services/restaurants/Filtering';
import DeliveryRow from './DeliveryRow';
import RestaurantHeader from './RestaurantHeader';
import MainDeliveryRow from './MainDeliveryRow';
import BackItem from '../BackItem';
import Breakpoint, { BREAKPOINTS } from '../breakpoint';
import { getLocalized } from '../../services/strings/Localisation';
import STYLES from './RestaurantDetail.module.scss';

import type { Restaurant, DeliveryOption, FiltersState } from '../../types';

type Props = {
  locale: string,
  restaurant: Restaurant,
  filtersState: FiltersState,
  onSelect: (DeliveryOption) => void,
  onMenuClick: () => void,
  onEcommerceClick: () => void,
  onPhoneClick: () => void,
  onWebClick?: () => void,
  onBack: () => void,
  noHeaders?: boolean,
}

const buildDeliveries = (
  locale: string, deliveries: Array<DeliveryOption>, onSelect: (DeliveryOption) => void, minDelivery: number,
) => deliveries
  .map((delivery) => (
    <DeliveryRow
      locale={locale}
      delivery={delivery}
      onClick={() => onSelect(delivery)}
      key={delivery.provider.id}
      cheapest={delivery.deliveryFee <= minDelivery}
    />
  ));


const buildDeliveriesContent = (
  locale: string, deliveries: Array<DeliveryOption>, filtersState: FiltersState, onSelect: (DeliveryOption) => void,
) => {
  const prices: Array<number> = deliveries.map((d) => d.deliveryFee);
  const minDelivery = Math.min(...prices);

  const filteredDeliveries = filterDeliveries(deliveries, filtersState);

  if (filteredDeliveries.length === 0 || deliveries.length === filteredDeliveries.length) {
    return buildDeliveries(locale, deliveries, onSelect, minDelivery);
  }

  const filteredIds = filteredDeliveries.map((d) => d.provider.id);
  const extraDeliveries = deliveries.filter((d) => filteredIds.indexOf(d.provider.id) === -1);
  const separatorText = getLocalized(locale, 'Detail separator');

  return (
    <>
      {buildDeliveries(locale, filteredDeliveries, onSelect, minDelivery)}
      <div className={STYLES.Separator}>{separatorText}</div>
      {buildDeliveries(locale, extraDeliveries, onSelect, minDelivery)}
    </>
  );
};

const RestaurantDetail = (props: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    Analytics.pageView('detail');
  }, []);

  const {
    locale, restaurant, filtersState, onSelect, onBack, onMenuClick,
    onEcommerceClick, onPhoneClick, onWebClick, noHeaders = false,
  } = props;

  const { mainDelivery, deliveries } = restaurant;

  const deliveriesContent = buildDeliveriesContent(locale, deliveries, filtersState, onSelect);

  const headerClick = mainDelivery && mainDelivery.webLink ? onWebClick : undefined;

  return (
    <div className={STYLES.Container}>
      { !noHeaders && (
      <>
        <Breakpoint query={BREAKPOINTS.ABOVE_TABLET}>
          <BackItem locale={locale} onClick={onBack} />
        </Breakpoint>
        <RestaurantHeader locale={locale} restaurant={restaurant} onClick={headerClick} />
      </>
      )}
      { mainDelivery
      && (
      <MainDeliveryRow
        locale={locale}
        delivery={mainDelivery}
        onEcommerceClick={mainDelivery.ecommerceLink ? onEcommerceClick : undefined}
        onMenuClick={onMenuClick}
        onPhoneClick={onPhoneClick}
      />
      ) }
      { deliveriesContent }
    </div>
  );
};

RestaurantDetail.defaultProps = {
  noHeaders: false,
  onWebClick: undefined,
};

export default RestaurantDetail;
