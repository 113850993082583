export const SEARCH_RESTAURANTS_PATH = 'restaurants/search';
export const RESTAURANTS_PATH = 'restaurants';
export const LOCATIONS_PATH = 'locations';

export const SEARCH_RESTAURANTS_TIMEOUT = 10;

export const IMAGES = {
  glovo: 'https://img.delitbee.com/providers/glovo.svg',
  uberEats: 'https://img.delitbee.com/providers/uber.svg',
  deliveroo: 'https://img.delitbee.com/providers/deliveroo.svg',
  justEat: 'https://img.delitbee.com/providers/justeat.svg',
  lanzadera: 'https://img.delitbee.com/media/logo-lanzadera.svg',
};

export const LINKS = {
  twitter: 'https://twitter.com/delitbee',
  instagram: 'https://www.instagram.com/delitbee',
  facebook: 'https://www.facebook.com/Delitbee-113619000359125',
  linkedin: 'https://www.linkedin.com/company/delitbee',
  lanzadera: 'https://lanzadera.es/',
};

export const CONSENT_COOKIE_NAME = 'gdpr-consent-cookie';
export const INTERNAL_USER_COOKIE_NAME = 'dtb-internal-cookie';
export const INTERNAL_USER_QUERY_PARAM = 'internal_user';

export const UNKNOWN_FEE_VALUE = 1882.69;
