/* eslint-disable */
const branchLoader = (b, r, a, n, c, h, _, s, d, k) => {
  if (!b[n] || !b[n]._q) {
    for (; s < _.length;) c(h, _[s++]);
    d = r.createElement(a);
    d.async = 1;
    d.src = 'https://cdn.branch.io/branch-latest.min.js';
    k = r.getElementsByTagName(a)[0];
    k.parentNode.insertBefore(d, k);
    b[n] = h;
  }
};

const loadBranch = () => {
  const c = function (b, r) {
    b[r] = function () {
      b._q.push([r, arguments]);
    };
  };

  const h = {
    _q: [],
    _v: 1,
  };

  branchLoader(window, document, 'script', 'branch', c, h, 'addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking'.split(' '), 0);
};
/* eslint-enable */

export const branchInit = (branchKey) => {
  loadBranch();
  if (typeof window === 'undefined' || !window.branch || typeof window.branch.logEvent !== 'function') return;
  window.branch.init(branchKey);
};

export const branchConsent = (consent = true) => {
  if (typeof window === 'undefined' || !window.branch || typeof window.branch.logEvent !== 'function') return;
  if (consent) {
    window.branch.disableTracking(false);
  } else {
    window.branch.disableTracking(true);
  }
};

export const branchEvent = (eventName, params) => {
  if (typeof window === 'undefined' || !window.branch || typeof window.branch.logEvent !== 'function') return;
  window.branch.logEvent(eventName, params);
};
