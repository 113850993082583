// @flow
import Analytics from './Analytics';

export type Props = {
  url: string,
  tag: string,
  method?: 'GET' | 'POST',
  headers?: { [key: string]: string, ... },
  body?: string,
}

const measuredFetch = async (props: Props) => {
  const {
    url, tag, method = 'GET', headers = {}, body = undefined,
  } = props;
  const startTime = new Date();
  const finalHeaders = headers;
  if (method === 'POST') {
    finalHeaders['Content-Type'] = 'application/json';
  }
  const requestOptions: RequestOptions = {
    method,
    headers: finalHeaders,
    body,
  };
  try {
    const result = await fetch(url, requestOptions);
    return result;
  } finally {
    const endTime = new Date();
    Analytics.timing({
      category: 'Fetch',
      variable: tag,
      value: endTime - startTime, // in ms
    });
  }
};

export default measuredFetch;
