// @flow

import { getLocalized } from './Localisation';

import type {
  Food, FilterGroup, ApiError, ErrorInfo,
} from '../../types';

export const foodText = (food: Food, locale: string): string => {
  switch (food) {
    case 'break_&_dessert':
      return getLocalized(locale, 'Food break__and__dessert');
    case 'burger':
    case 'kebab':
    case 'pizza':
    case 'italian':
    case 'sushi':
    case 'asian':
    case 'mexican':
    case 'healthy':
    case 'poke':
    case 'arabic':
    case 'thai':
    case 'indian':
    case 'latin':
    case 'chicken':
    case 'meat':
    case 'other':
      return getLocalized(locale, `Food ${food}`);
    default:
      return getLocalized(locale, 'Food other');
  }
};

export const foodTexts = (foods: Array<Food>, locale: string): string => foods
  .sort((a, b) => {
    if (a === 'other') return 1;
    if (b === 'other') return -1;
    return 0;
  })
  .map((food) => foodText(food, locale))
  .join(', ');

export const filterGroupText = (
  filterGroup: FilterGroup,
  locale: string,
): string => {
  switch (filterGroup) {
    case 'provider':
    case 'food':
    case 'options':
      return getLocalized(locale, `Filter ${filterGroup}`);
    default:
      return '';
  }
};

export const apiErrorText = (error: ApiError, locale: string): ErrorInfo => {
  switch (error) {
    case 'networkError':
    case 'unknown':
    case 'serverError':
    case 'parsingError':
    default:
      return {
        normalMsg: getLocalized(locale, 'Api error msg'),
        highlightMsg: getLocalized(locale, 'Api error highlight'),
        buttonText: getLocalized(locale, 'Api error button'),
        buttonLink: '/',
      };
  }
};
