// @flow

import type {
  Restaurant, DeliveryOption, TimeRange, Food, RestaurantsResult, RestaurantDelivery,
} from '../../types';
import { UNKNOWN_FEE_VALUE } from '../../constants';
import imgFallback from '../../resources/img-restaurant-ph.svg';

const randomInt = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1) + min);

const randomFood = (): Food => {
  const random = randomInt(0, 12);
  switch (random) {
    case 1: return 'burger';
    case 2: return 'kebab';
    case 3: return 'pizza';
    case 4: return 'sushi';
    case 5: return 'asian';
    case 6: return 'mexican';
    case 7: return 'break_&_dessert';
    case 8: return 'healthy';
    case 9: return 'poke';
    case 10: return 'arabic';
    case 11: return 'thai';
    case 12:
    default: return 'other';
  }
};

const mockRestaurants = (): RestaurantsResult => {
  const result: Array<Restaurant> = [];
  for (let i = 1; i <= 100; i += 1) {
    const timeRange: TimeRange = {
      min: randomInt(10, 30),
      max: randomInt(30, 60),
    };
    const delivery: DeliveryOption = {
      deeplink: 'https://www.google.com',
      open: i % 2 === 0,
      provider: { id: 'glovo', name: 'Glovo' },
      delivery: true,
      collection: false,
      deliveryFee: (Math.random() * 4.0) + 1,
      minBasket: UNKNOWN_FEE_VALUE,
      deliveryTime: timeRange,
      discount: 'Some discount',
    };

    const restaurantDelivery: RestaurantDelivery = {
      id: `id-${i}`,
      open: (i + 1) % 2 === 0,
      address: 'Passeig Marítim, 17, Badalona',
      phoneNumber: '936764301',
      delivery: true,
      collection: true,
      // eslint-disable-next-line max-len
      timeTable: 'Lunes – Viernes 20:00h a 23:30h \nSábado - 12:00h a 16:00h & 20:00h a 23:30h\nDomingo - 12:00h a 16:00h',
      webLink: 'https://www.canpizza.eu',
      menuLink: 'https://www.canpizza.eu/wp-content/uploads/2020/05/CAN_PIZZA_CAS_CAT.pdf',
      mapLink: 'https://goo.gl/maps/QRJm8Gg6Xriauq1V8',
      ecommerceLink: i % 6 === 0 ? 'https://www.canpizza.eu/badalona-can-pizza/' : undefined,
      deliveryFee: 2.3,
      deliveryTime: { min: 20, max: 50 },
      minBasket: 8,
    };

    const name = i % 2 === 0 ? `Restaurant ${i}`
      : `Restaurant ${i} very long text that should overflow and not collide with the edges`;

    result.push({
      id: `id-${i}`,
      internalId: `id-${i}`,
      name,
      icon: imgFallback,
      foods: [randomFood()],
      collection: true,
      delivery: true,
      offers: true,
      recommended: i % 6 === 0,
      deliveries: [delivery],
      mainDelivery: i % 3 === 0 ? restaurantDelivery : undefined,
    });
  }
  return { restaurants: result };
};

export default mockRestaurants;
