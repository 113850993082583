// @flow

import React from 'react';
import Card from '../card';
import ResultsHeader from '../resultsheader';
import PrimaryButton from '../PrimaryButton';
import ErrorSection from '../ErrorSection';
import RestaurantRow from './RestaurantRow';
import ResultsCounter from './ResultsCounter';
import { getLocalized } from '../../services/strings/Localisation';
import STYLES from './ResultsList.module.scss';

import type { Restaurant, ErrorInfo } from '../../types';

type Props = {
  locale: string,
  classNames: string,
  searchText: string,
  onSearch: string => void,
  isLoading: boolean,
  error?: ErrorInfo,
  restaurants: Array<Restaurant>,
  totalRestaurants: number,
  showMoreButton: boolean,
  onSelect: (Restaurant) => void,
  onShowAll: (source: string) => void,
}

const ResultsList = (props: Props) => {
  const {
    searchText, onSearch, onShowAll, restaurants, totalRestaurants, isLoading, error, onSelect, classNames,
    showMoreButton, locale,
  } = props;

  let content;
  if (isLoading) {
    content = [...Array(5).keys()].map((idx) => (<Card key={`empty-${idx}`} />));
  } else if (error) {
    content = (
      <div className={STYLES.Error}>
        <ErrorSection
          normalMsg={error.normalMsg}
          highlightMsg={error.highlightMsg}
          buttonText={error.buttonText}
          buttonLink={error.buttonLink}
        />
      </div>
    );
  } else {
    const restaurantsContent = restaurants
      .map((restaurant) => (
        <RestaurantRow
          locale={locale}
          restaurant={restaurant}
          onClick={() => onSelect(restaurant)}
          key={restaurant.id}
        />
      ));
    content = (
      <>
        <ResultsCounter
          locale={locale}
          current={restaurants.length}
          total={totalRestaurants}
          onShowAll={() => onShowAll('results-counter')}
        />
        {restaurantsContent}
        { showMoreButton && (
          <div className={STYLES.ShowMore}>
            <PrimaryButton
              title={getLocalized(locale, 'Results all button')}
              onClick={() => onShowAll('show-more-btn')}
              type="secondary"
            />
          </div>
        )}
      </>
    );
  }

  return (
    <div className={`${STYLES.Container} ${classNames}`}>
      <ResultsHeader locale={locale} text={searchText} onSearch={onSearch} />
      { content }
    </div>
  );
};

ResultsList.defaultProps = {
  error: undefined,
};

export default ResultsList;
