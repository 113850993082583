// @flow

import React from 'react';
import Card from '../card';
import { buildTranslate } from '../../services/strings/Localisation';
import backIcon from '../../resources/ic-back.svg';
import STYLES from './BackItem.module.scss';

type Props = {
    locale: string,
    onClick?: () => void,
};

const BackItem = (props: Props) => {
  const { onClick, locale } = props;
  const t = buildTranslate(locale);
  return (
    <Card noPadding disclosure="none" onClick={onClick}>
      <div className={STYLES.Header}>
        <img src={backIcon} alt={t('Back')} />
        <p>
          {`${t('Back item text1')} `}
          <b>{t('Back item text2')}</b>
        </p>
      </div>
    </Card>
  );
};

BackItem.defaultProps = {
  onClick: undefined,
};

export default BackItem;
