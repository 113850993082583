// @flow
import type { Config } from '../types';

const config: Config = {
  apiHost: 'https://api.delitbee.com/',
  gAnalyticsId: 'UA-165037954-2',
  gTagId: 'G-CJZVDSQ0TK',
  mixpanelId: 'b8af42ae027d91f6880dbe3cb6328161',
  amplitudeKey: 'f9963c758b181fc0447b15165212b828',
  fbPixelId: '375322560515887',
  branchKey: 'key_test_doTXK7kf6F72rsCtCpYc2ggbwyarDnRd',
  googleApiKey: 'AIzaSyDKGAuIeRpMyzHrUW9CNWp0AB5NrnGrwco',
};

export default config;
