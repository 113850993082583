// @flow

import React from 'react';
import Switch from '../switch';
import type { FilterItem } from '../../types';

import STYLES from './Filters.module.scss';

type Props = {
  item: FilterItem,
  selected: boolean,
  onClick: (string) => void,
}

const FiterItemComponent = (props: Props) => {
  const {
    item, selected, onClick,
  } = props;

  return (
    <div className={STYLES.Row}>
      { item.icon
        && <img src={item.icon} alt="Icon" />}
      <div className={STYLES.ItemText}>{item.text}</div>
      <Switch selected={selected} onClick={() => onClick(item.id)} />
    </div>

  );
};

export default FiterItemComponent;
