module.exports = {
  breakpointDesktop: '75rem',
  breakpointMobile: '37.5rem',
  breakpointQueryAboveMobile: '(min-width: 37.55rem)',
  breakpointQueryAboveTablet: '(min-width: 57.55rem)',
  breakpointQueryMobile: '(max-width: 37.5rem)',
  breakpointQueryTablet: '(max-width: 57.5rem)',
  breakpointQueryTabletOnly: '(min-width: 37.55rem) and (max-width: 57.5rem)',
  breakpointTablet: '57.55rem',
};
