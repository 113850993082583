// @flow

import * as React from 'react';
import Modal from './Modal';

type Props = {
  visible?: boolean,
  children?: React.Node,
};

const ModalController = (props: Props) => {
  const { visible, children } = props;
  return visible ? <Modal>{children}</Modal> : null;
};

ModalController.defaultProps = {
  visible: true,
  children: null,
};

export default ModalController;
