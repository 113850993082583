// @flow

export const doRedirect = (url: string, newTab: boolean = false) => {
  if (newTab) {
    const win = window.open(url);
    if (win && win.focus) {
      win.focus();
    }
  } else {
    setTimeout(() => { window.location.href = url; }, 250);
  }
};

export const dummy = () => {};
