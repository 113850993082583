// @flow

export const setBodyClass = (className: string) => {
  if (document.body) {
    document.body.setAttribute('class', className);
  }
};

export const clearBodyClasses = () => {
  if (document.body) {
    document.body.removeAttribute('class');
  }
};
