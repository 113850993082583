const fbEvents = (f, b, e, v, n, t, s) => {
  /* eslint-disable */
  if (f.fbq) return; n = f.fbq = function () {
    n.callMethod
      ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
  };
  if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
  n.queue = []; t = b.createElement(e); t.async = !0;
  t.src = v; s = b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t, s);
  /* eslint-enable */
};

export const fbInit = (pixelId) => {
  fbEvents(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  if (typeof window.fbq !== 'function') { return; }
  window.fbq('init', pixelId);
  window.fbq('track', 'PageView');
};

let fbConsent = false;

export const fbSetConsent = (consent) => {
  fbConsent = consent;
};

export const fbEvent = (eventName, params = {}) => {
  if (!fbConsent || typeof window.fbq !== 'function') { return; }
  window.fbq('trackCustom', eventName, params);
};
