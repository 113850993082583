// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
} from 'react-router-dom';
import Analytics from './services/Analytics';
import './styles/normalize.css';
import './index.scss';
import App from './App';
import { extractServerProps } from './services/serverProps';
import * as serviceWorker from './services/serviceWorker';

Analytics.initialize();

// Performance metrics
if (typeof PerformanceObserver !== 'undefined') {
  const callback = (list) => {
    list.getEntries().forEach((entry: PerformanceEntry) => {
      Analytics.timing({
        category: 'performance',
        variable: `page-load-${entry.name}`,
        value: entry.duration,
      });
    });
  };

  const observer = new PerformanceObserver(callback);
  observer.observe({ entryTypes: ['navigation'] });
}

const serverSideParams = extractServerProps({ locale: 'es' });

const root = document.getElementById('root');
if (root) {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <App locale={serverSideParams.locale} />
      </BrowserRouter>
    </React.StrictMode>,
    root,
  );
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
