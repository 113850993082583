// @flow

import winston, { type Levels, type Logger } from 'winston';

const logger: Logger<Levels> = winston.createLogger({
  transports: [
    new winston.transports.Console(),
  ],
});

const info = (message: string) => {
  logger.info(message);
};

const warning = (message: string) => {
  logger.warn(message);
};

const error = (message: string) => {
  logger.error(message);
};

const exception = (message: string, e: Error) => {
  logger.error(`${message}: ${e.stack}`);
};

export default {
  info, warning, error, exception,
};
