// eslint-disable-next-line func-names
const gtag = function () {
  // Can't use arrow func + destructuring as Google expects
  // arguments objects in dataLayer (not an array of arguments).
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
};

let currentTrackingId = null;

export const gtagInit = (analyticsId, gtagId) => {
  const scriptId = 'ga-gtag';

  if (document.getElementById(scriptId)) return;

  const { head } = document;
  const script = document.createElement('script');
  script.id = scriptId;
  script.type = 'text/javascript';
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;
  head.insertBefore(script, head.firstChild);

  window.dataLayer = window.dataLayer || [];

  gtag('js', new Date());
  gtag('config', gtagId);
  currentTrackingId = gtagId;
  gtag('config', analyticsId);
};

export const gtagPageChanged = () => {
  gtag('config', currentTrackingId, window.location.pathname);
};

export const gtagEvent = (eventName, params) => {
  const googleParams = {};
  Object.keys(params)
    .forEach((key) => {
      googleParams[key.replaceAll('-', '_')] = params[key];
    });
  const googleName = eventName.replaceAll('-', '_');
  gtag('event', googleName, googleParams);
};
