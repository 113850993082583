// @flow

import React from 'react';
import ListItem from '../listitem';
import promoImg from '../../resources/ic-promo.svg';
import deliveryImg from '../../resources/ic-delivery.svg';
import pickupImg from '../../resources/ic-pickup.svg';
import glovoImg from '../../resources/img-glovo.svg';
import ubereatsImg from '../../resources/img-ubereats.svg';
import justeatImg from '../../resources/img-justeat.svg';
import deliverooImg from '../../resources/img-deliveroo.svg';
import { deliveryOptionLabels } from './utils';

import type { Label, ImgConfig } from '../listitem/types';
import type { DeliveryOption, Provider } from '../../types';

type Props = {
    locale: string,
    delivery: DeliveryOption,
    cheapest?: boolean,
    fastest?: boolean,
    onClick: () => void,
}

const getProviderImg = (provider: Provider) => {
  switch (provider.id) {
    case 'uber': return ubereatsImg;
    case 'justeat': return justeatImg;
    case 'deliveroo': return deliverooImg;
    case 'glovo': return glovoImg;
    default: return undefined;
  }
};

const DeliveryRow = (props: Props) => {
  const {
    locale, delivery, onClick, cheapest, fastest,
  } = props;
  const {
    delivery: hasDelivery, collection, discount, provider, open,
  } = delivery;

  const providerImg = provider.logo || getProviderImg(provider);

  const icons = [];
  if (discount) {
    icons.push(promoImg);
  }
  if (hasDelivery) {
    icons.push(deliveryImg);
  }
  if (collection) {
    icons.push(pickupImg);
  }

  const labels: Array<Label> = deliveryOptionLabels(delivery, locale);

  const imgConfig: ImgConfig = {
    squared: true,
    fee: cheapest,
    speed: fastest,
  };

  return (
    <ListItem
      img={providerImg}
      title={provider.name}
      subtitle=""
      onClick={onClick}
      icons={icons}
      labels={labels}
      boxText={discount}
      imgConfig={imgConfig}
      status={open ? 'none' : 'closed'}
    />
  );
};

DeliveryRow.defaultProps = {
  cheapest: false,
  fastest: false,
};

export default DeliveryRow;
