// @flow

import React, { useEffect, useState } from 'react';
import Analytics from '../../services/Analytics';
import CookieBanner from '../../components/CookieBanner';
import Filters, { ModalFilters } from '../../components/filters';
import Breakpoint, { BREAKPOINTS } from '../../components/breakpoint';
import ResultsList from '../../components/Results';
import RestaurantDetail from '../../components/restaurantdetail';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import Background from '../../components/background';
import STYLES from './Results.module.scss';

import type {
  Restaurant, DeliveryOption, FiltersVisibility, FiltersState, FilterGroup, ErrorInfo,
} from '../../types';

import { setBodyClass } from '../../utils/bodyClasses';

type Props = {
  locale: string,
  textFilter: string,
  restaurants: Array<Restaurant>,
  totalRestaurants: number,
  showMoreButton: boolean,
  loading: boolean,
  filtersVisibility: FiltersVisibility,
  filtersState: FiltersState,
  error?: ErrorInfo,
  selectedRestaurant?: Restaurant,
  onTextFilter: (text: string) => void,
  onSelect: (restaurant: Restaurant) => void,
  onDelivery: (restaurant: Restaurant, delivery: DeliveryOption) => void,
  onMenuClick: (Restaurant) => void,
  onEcommerceClick: (Restaurant) => void,
  onPhoneClick: (Restaurant) => void,
  onWebClick: (Restaurant) => void,
  onBackBtn: () => void,
  onClearFilter: (group: FilterGroup) => void,
  onFilterClick: (group: FilterGroup, filterId: string) => void,
  onShowAll: (source: string) => void,
};

const Results = (props: Props) => {
  useEffect(() => {
    Analytics.pageView('results');
    setBodyClass('results-body');
  }, []);

  const {
    filtersVisibility, filtersState, onClearFilter, onFilterClick, onBackBtn, onShowAll, selectedRestaurant,
    textFilter, restaurants, totalRestaurants, loading, error, onTextFilter, onSelect,
    onDelivery, onMenuClick, onEcommerceClick, onPhoneClick, onWebClick, showMoreButton, locale,
  } = props;

  const [filtersModalVisible, setFiltersModalVisible] = useState(false);

  const onModalClose = () => {
    setFiltersModalVisible(false);
    Analytics.navigation({
      name: 'filters-close',
      source: 'filters-modal',
      destination: 'results',
    });
  };

  const onNavbarFilters = () => {
    setFiltersModalVisible(true);
    Analytics.navigation({
      name: 'filters-open',
      source: 'results',
      destination: 'filters-modal',
    });
  };

  const filtersText = 'Filtros';
  const navbarRightButton = selectedRestaurant ? undefined : { onClick: onNavbarFilters, text: filtersText };

  return (
    <>
      <Breakpoint query={BREAKPOINTS.TABLET}>
        <ModalFilters
          locale={locale}
          visible={filtersModalVisible}
          filtersVisibility={filtersVisibility}
          filtersState={filtersState}
          onClear={onClearFilter}
          onItemClick={onFilterClick}
          onReset={() => onShowAll('filters-reset')}
          onClose={onModalClose}
        />
      </Breakpoint>
      <Navbar locale={locale} onBack={onBackBtn} onlyTablet rightButton={navbarRightButton} />
      <div className={STYLES.Container}>
        <div className={STYLES.Sidebar}>
          <Filters
            locale={locale}
            showLogo
            filtersVisibility={filtersVisibility}
            filtersState={filtersState}
            onClear={onClearFilter}
            onItemClick={onFilterClick}
          />
        </div>
        <ResultsList
          locale={locale}
          classNames={selectedRestaurant ? STYLES.Hidden : ''}
          searchText={textFilter}
          onSearch={onTextFilter}
          onSelect={onSelect}
          onShowAll={onShowAll}
          isLoading={loading}
          error={error}
          restaurants={restaurants}
          totalRestaurants={totalRestaurants}
          showMoreButton={showMoreButton}
        />
        { selectedRestaurant
            && (
            <RestaurantDetail
              locale={locale}
              restaurant={selectedRestaurant}
              filtersState={filtersState}
              onSelect={(delivery) => onDelivery(selectedRestaurant, delivery)}
              onMenuClick={() => onMenuClick(selectedRestaurant)}
              onEcommerceClick={() => onEcommerceClick(selectedRestaurant)}
              onPhoneClick={() => onPhoneClick(selectedRestaurant)}
              onWebClick={() => onWebClick(selectedRestaurant)}
              onBack={onBackBtn}
            />
            )}
      </div>
      <Footer locale={locale} />
      <Background onlyDesktop />
      <CookieBanner locale={locale} />
    </>
  );
};

Results.defaultProps = {
  error: undefined,
  selectedRestaurant: undefined,
};

export default Results;
