// @flow

import React from 'react';
import Filters from './Filters';
import ModalScreen from '../ModalScreen';
import { buildTranslate } from '../../services/strings/Localisation';

import type { FiltersVisibility, FiltersState, FilterGroup } from '../../types';

type Props = {
  locale: string,
  visible: boolean,
  filtersVisibility: FiltersVisibility,
  filtersState: FiltersState,
  onClear: (FilterGroup) => void,
  onItemClick: (FilterGroup, string) => void,
  onReset: () => void,
  onClose: () => void,
}

const ModalFilters = (props: Props) => {
  const {
    locale, visible, filtersVisibility, filtersState, onClear, onItemClick, onReset, onClose,
  } = props;
  const t = buildTranslate(locale);

  const leftButton = {
    onClick: onClose,
    text: t('Apply'),
  };

  const rightButton = {
    onClick: onReset,
    text: t('Filters clear all'),
  };

  return (
    <ModalScreen
      locale={locale}
      visible={visible}
      title={t('Filters title')}
      leftButton={leftButton}
      rightButton={rightButton}
      onClose={onClose}
    >
      <Filters
        locale={locale}
        showLogo={false}
        filtersVisibility={filtersVisibility}
        filtersState={filtersState}
        onClear={onClear}
        onItemClick={onItemClick}
        embedded
      />
    </ModalScreen>
  );
};

export default ModalFilters;
