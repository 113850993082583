// @flow

import type {
  Restaurant, FilterItem, FiltersVisibility, FilterGroup, FiltersState, DeliveryOption,
} from '../../types';
import {
  foodTexts, foodText, filterGroupText,
} from '../strings/TypeMapper';
import { getLocalized } from '../strings/Localisation';
import promoImg from '../../resources/ic-promo.svg';
import deliveryImg from '../../resources/ic-delivery.svg';
import pickupImg from '../../resources/ic-pickup.svg';

type ItemValues = (restaurant: Restaurant) => Array<FilterItem>
type ItemValuesChecker = { [key: FilterGroup]: ItemValues }
type ItemChecker = { [key: FilterGroup]: (restaurant: Restaurant, values: Array<string>) => boolean }
type DeliveryChecker = { [key: FilterGroup]: (delivery: DeliveryOption, values: Array<string>) => boolean }

const normalize = (text: string): string => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

export const filterByNameOrFood = async (
  restaurants: Array<Restaurant>,
  query: string,
  locale: string,
): Promise<Array<Restaurant>> => {
  if (!query || typeof query !== 'string') {
    return restaurants;
  }
  const queryNorm = normalize(query);
  return restaurants.filter((restaurant) => {
    const { name, foods } = restaurant;
    const nameNorm = normalize(name);
    const foodNorm = normalize(foodTexts(foods, locale));
    return nameNorm.indexOf(queryNorm) !== -1 || foodNorm.indexOf(queryNorm) !== -1;
  });
};

const filterValuesCheckers: (string) => ItemValuesChecker = (locale: string) => ({
  options: (restaurant) => {
    const result = [];
    if (restaurant.offers) {
      result.push({
        id: 'offers',
        text: getLocalized(locale, 'Filter offers'),
        icon: promoImg,
      });
    }
    if (restaurant.delivery) {
      result.push({
        id: 'delivery',
        text: getLocalized(locale, 'Filter delivery'),
        icon: deliveryImg,
      });
    }
    if (restaurant.collection) {
      result.push({
        id: 'collection',
        text: getLocalized(locale, 'Filter collection'),
        icon: pickupImg,
      });
    }
    return result;
  },
  provider: (restaurant) => restaurant.deliveries.map((d) => ({ id: d.provider.id, text: d.provider.name })),
  food: (restaurant) => restaurant.foods.map((food) => ({ id: food, text: foodText(food, locale) })),
  hidden: (restaurant) => (restaurant.recommended ? [{ id: 'recommended', text: '' }] : []),
});

const restaurantOptionsChecker = (restaurant: Restaurant, value: string): boolean => {
  switch (value) {
    case 'offers': return restaurant.offers;
    case 'delivery': return restaurant.delivery;
    case 'collection': return restaurant.collection;
    default: return false;
  }
};

const deliveryOptionsChecker = (delivery: DeliveryOption, value: string): boolean => {
  switch (value) {
    case 'offers': return !!delivery.discount;
    case 'collection': return delivery.collection;
    default: return false;
  }
};

const hiddenChecker = (restaurant: Restaurant, values: Array<string>): boolean => {
  if (values.indexOf('recommended') !== -1) {
    return restaurant.recommended;
  }
  return true;
};

const restaurantFilterChecker: ItemChecker = {
  provider: (
    restaurant: Restaurant,
    values: Array<string>,
  ): boolean => values.length === 0
  || values.some((value) => restaurant.deliveries.map((d) => d.provider.id).indexOf(value) !== -1),
  food: (
    restaurant: Restaurant,
    values: Array<string>,
  ): boolean => values.length === 0
  || values.some((value) => restaurant.foods.indexOf(value) !== -1),
  options: (
    restaurant: Restaurant,
    values: Array<string>,
  ): boolean => values.length === 0 || values.every((value) => restaurantOptionsChecker(restaurant, value)),
  hidden: (
    restaurant: Restaurant,
    values: Array<string>,
  ): boolean => values.length === 0 || hiddenChecker(restaurant, values),
};

const deliveryFilterChecker: DeliveryChecker = {
  provider: (
    delivery: DeliveryOption,
    values: Array<string>,
  ): boolean => values.length === 0 || values.indexOf(delivery.provider.id) !== -1,
  food: (): boolean => true,
  options: (
    delivery: DeliveryOption,
    values: Array<string>,
  ): boolean => values.length === 0 || values.every((value) => deliveryOptionsChecker(delivery, value)),
  hidden: (): boolean => true,
};

export const computeFiltersVisibility = async (
  restaurants: Array<Restaurant>,
  locale: string): Promise<FiltersVisibility> => {
  const resultsMap: { [key: FilterGroup]: Array<FilterItem> } = {};
  const itemIdSet = new Set();
  const valuesCheckers = filterValuesCheckers(locale);
  Object.keys(valuesCheckers).forEach((k) => { resultsMap[k] = []; });
  restaurants.forEach((restaurant) => {
    Object.keys(valuesCheckers).forEach((key) => {
      const newItems = valuesCheckers[key](restaurant);
      newItems.forEach((newItem) => {
        if (!itemIdSet.has(newItem.id)) {
          resultsMap[key].push(newItem);
          itemIdSet.add(newItem.id);
        }
      });
    });
  });
  return Object.keys(resultsMap).filter((key) => resultsMap[key].length).map((key) => ({
    id: key,
    text: filterGroupText(key, locale),
    items: resultsMap[key].sort() // 1st alphabetically
      .sort((a, b) => {
        if (a.id === 'other') return 1;
        if (b.id === 'other') return -1;
        return a.text.localeCompare(b.text);
      }),
    visible: key !== 'hidden',
  }));
};

export const initialFilterState = (visibilty: FiltersVisibility): FiltersState => {
  const hiddenIdx = visibilty.map((group) => group.id).indexOf('hidden');
  const hasRecommended = hiddenIdx !== -1 && visibilty[hiddenIdx]
    .items.map((item) => item.id).indexOf('recommended') !== -1;

  return hasRecommended ? { hidden: ['recommended'] } : {};
};

export const filterByFilterState = async (
  restaurants: Array<Restaurant>,
  filtersState: FiltersState): Promise<Array<Restaurant>> => restaurants
  .filter((restaurant) => Object.keys(filtersState)
    .every((key) => restaurantFilterChecker[key](restaurant, filtersState[key])));

export const filterDeliveries = (
  deliveries: Array<DeliveryOption>,
  filtersState: FiltersState,
): Array<DeliveryOption> => deliveries
  .filter((delivery) => Object.keys(filtersState)
    .every((key) => deliveryFilterChecker[key](delivery, filtersState[key])));

export const toggleFilter = (filtersState: FiltersState, group: FilterGroup, filterId: string): FiltersState => {
  const result = filtersState;
  const items = filtersState[group] || [];
  const index = items.indexOf(filterId);
  if (index !== -1) {
    items.splice(index, 1);
  } else {
    items.push(filterId);
  }
  result[group] = items;
  return result;
};

export const switchOffFilter = (filtersState: FiltersState, group: FilterGroup, filterId: string): FiltersState => {
  const result = filtersState;
  const items = filtersState[group] || [];
  const index = items.indexOf(filterId);
  if (index === -1) return result;
  items.splice(index, 1);
  result[group] = items;
  return result;
};
