// @flow

import { SEARCH_RESTAURANTS_PATH, RESTAURANTS_PATH } from '../../constants';
import config from '../../config';
import { mapRestaurants, mapQuery, mapRestaurant } from './restaurantMapper';
import Logger from '../Logger';
import measuredFetch from '../measuredFetch';
import { parseQueryParams } from '../queryParams';
import type {
  RestaurantsResult, RestaurantResult, GetRestaurantResult,
} from '../../types';

const searchWithLocationId = async (
  locationId: string,
  locale: string = 'es-ES',
  restaurantId?: string,
): Promise<RestaurantsResult> => {
  const filterIds = restaurantId ? `&filter_ids=${restaurantId}` : '';
  const queryParams = `locale=${locale}&onlyRecommended=false${filterIds}`;
  const finalUrl = `${config.apiHost}${SEARCH_RESTAURANTS_PATH}/${encodeURIComponent(locationId)}?${queryParams}`;
  let response;
  try {
    response = await measuredFetch({ url: finalUrl, tag: 'restaurants-search' });
  } catch (e) {
    Logger.exception('error fetching restaurants', e);
    return {
      error: 'networkError',
      restaurants: [],
    };
  }
  if (response.ok) {
    try {
      const json = await response.json();
      const restaurants = mapRestaurants(json);
      const query = mapQuery(json);
      return {
        restaurants,
        query,
      };
    } catch (e) {
      Logger.exception('error parsing fetched restaurants', e);
      return {
        error: 'parsingError',
        restaurants: [],
      };
    }
  }
  Logger.error(`restaurants response not ok: ${response.status}`);
  return {
    error: 'serverError',
    restaurants: [],
  };
};

export const searchWithUrlParams = async (params: string): Promise<RestaurantsResult> => {
  const inputQueryParams = parseQueryParams(params);
  return searchWithLocationId(inputQueryParams.loc, inputQueryParams.locale || 'es-ES');
};

export const searchRestaurant = async (
  locationId: string,
  restaurantId: string,
  locale: string = 'es-ES',
): Promise<RestaurantResult> => {
  const { error, restaurants, query } = await searchWithLocationId(locationId, locale, restaurantId);
  if (restaurants && restaurants.length > 0) {
    return {
      error,
      restaurant: restaurants[0],
      query,
    };
  }
  return { error, query };
};


export const getRestaurant = async (
  restaurantSlug: string,
): Promise<GetRestaurantResult> => {
  const finalUrl = `${config.apiHost}${RESTAURANTS_PATH}/${restaurantSlug}`;
  let response;
  try {
    response = await measuredFetch({ url: finalUrl, tag: 'get-restaurant' });
  } catch (e) {
    Logger.exception('error fetching restaurant', e);
    return {
      error: 'networkError',
    };
  }
  if (response.ok) {
    try {
      const json = await response.json();
      const restaurant = mapRestaurant(json);
      return {
        restaurant,
      };
    } catch (e) {
      Logger.exception('error parsing fetched restaurants', e);
      return {
        error: 'parsingError',
        restaurants: [],
      };
    }
  }
  Logger.error(`get restaurant response not ok: ${response.status}`);
  return {
    error: 'serverError',
    restaurants: [],
  };
};
