// @flow

import React from 'react';
import ListItem from '../listitem';
import icCalendar from '../../resources/ic-calendar.svg';
import icPhone from '../../resources/ic-telephone.svg';
import promoImg from '../../resources/ic-promo.svg';
import pickupImg from '../../resources/ic-pickup.svg';
import deliveryImg from '../../resources/ic-delivery.svg';
import { restaurantDeliveryLabels } from './utils';
import { buildTranslate } from '../../services/strings/Localisation';

import type { RestaurantDelivery } from '../../types';
import type { ListItemButton } from '../listitem/types';


type Props = {
    locale: string,
    delivery: RestaurantDelivery,
    onMenuClick: () => void,
    onPhoneClick: () => void,
    onEcommerceClick?: () => void,
}

const MainDeliveryRow = (props: Props) => {
  const {
    locale, delivery, onMenuClick, onPhoneClick, onEcommerceClick,
  } = props;
  const t = buildTranslate(locale);

  const buttons: Array<ListItemButton> = [];
  if (delivery.menuLink) {
    buttons.push({
      type: 'text',
      isCta: false,
      onClick: onMenuClick,
      text: t('Detail see menu'),
    });
  }
  if (delivery.phoneNumber) {
    buttons.push({
      isCta: true,
      onClick: onPhoneClick,
      text: delivery.phoneNumber,
      img: icPhone,
    });
  }

  const icons = [];
  if (delivery.offer) {
    icons.push(promoImg);
  }
  if (delivery.delivery) {
    icons.push(deliveryImg);
  }
  if (delivery.collection) {
    icons.push(pickupImg);
  }

  const labels = restaurantDeliveryLabels(delivery, locale);
  const timetable = delivery.timeTable ? {
    icon: icCalendar,
    alt: t('Detail timetable'),
    text: delivery.timeTable || '',
  } : undefined;

  return (
    <ListItem
      onClick={onEcommerceClick}
      title={t('Detail order direct')}
      subtitle={delivery.address || ''}
      subtitleHref={delivery.mapLink}
      textIcon={timetable}
      icons={icons}
      buttons={buttons}
      labels={labels}
      boxText={delivery.offer}
      status={delivery.open ? 'none' : 'closed'}
    />
  );
};

MainDeliveryRow.defaultProps = {
  onEcommerceClick: undefined,
};

export default MainDeliveryRow;
