// @flow

import * as React from 'react';
import { createPortal } from 'react-dom';

type Props = {
  children?: React.Node,
};

const modalRoot = typeof document !== 'undefined' ? document.getElementById('modal') : undefined;

class Modal extends React.Component<Props> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    children: null,
  };

  element: HTMLDivElement;

  constructor(props: Props) {
    super(props);
    // We create an element div for this modal
    if (typeof document !== 'undefined') {
      this.element = document.createElement('div');
    }
  }

  // We append the created div to the div#modal
  componentDidMount() {
    if (modalRoot) {
      modalRoot.appendChild(this.element);
    }
  }

  /**
    * We remove the created div when this Modal Component is unmounted
    * Used to clean up the memory to avoid memory leak
    */
  componentWillUnmount() {
    if (modalRoot) {
      modalRoot.removeChild(this.element);
    }
  }

  render() {
    const { children } = this.props;
    return createPortal(children, this.element);
  }
}

export default Modal;
