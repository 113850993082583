// @flow

import * as React from 'react';
import Modal from '../Modal';
import NavBar from '../navbar';
import { getLocalized } from '../../services/strings/Localisation';

import STYLES from './ModalScreen.module.scss';

type NavbarButton = {
  text: string,
  onClick: () => void,
}

type Props = {
  locale: string,
  visible?: boolean,
  children?: React.Node,
  title?: string,
  onClose: () => void,
  leftButton?: NavbarButton,
  rightButton?: NavbarButton,
};

const ModalScreen = (props: Props) => {
  const {
    locale, visible, children, title, onClose, leftButton, rightButton,
  } = props;


  const navBarLeftButton = leftButton || { onClick: onClose, text: getLocalized(locale, 'Close') };

  return (
    <Modal visible={visible}>
      <div className={STYLES.Container}>
        <div
          className={STYLES.Background}
          role="button"
          aria-label={getLocalized(locale, 'Close')}
          onClick={onClose}
          onKeyPress={onClose}
          tabIndex="0"
        />
        <div className={STYLES.Screen}>
          <NavBar locale={locale} leftButton={navBarLeftButton} titleText={title} rightButton={rightButton} />
          <div className={STYLES.Content}>
            {children}
          </div>
        </div>
      </div>
    </Modal>
  );
};

ModalScreen.defaultProps = {
  visible: true,
  title: undefined,
  children: null,
  leftButton: undefined,
  rightButton: undefined,
};

export default ModalScreen;
