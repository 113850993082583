// @flow

import * as React from 'react';
import Analytics from '../../services/Analytics';
import STYLES from './Link.module.scss';

type Props = {
    href: string,
    tag: string,
    description: string,
    onClick?: () => void,
    newTab?: boolean,
    children?: React.Node,
}

const Link = (props: Props) => {
  const {
    href, tag, description, newTab, children, onClick: onClickCaller,
  } = props;

  const onClick = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    if (onClickCaller) {
      onClickCaller();
    }
    Analytics.outboundLink({
      tag,
      description,
      url: href,
      newTab,
    });
  };

  const aProps: any = {
    className: STYLES.Link,
    href,
    onClick,
    onKeyPress: onClick,
    tabIndex: '0',
  };

  if (newTab) {
    aProps.target = '_blank';
    aProps.rel = 'noopener noreferrer';
  }

  return React.createElement('a', aProps, children);
};

Link.defaultProps = {
  newTab: false,
  children: null,
  onClick: undefined,
};

export default Link;
