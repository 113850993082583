// @flow

import React from 'react';

export const parseLineJumps = (text: string): any => {
  const parts = text.split('\n');
  return parts.map((part) => (
    <>
      {part}
      <br />
    </>
  ));
};

export const dummy = () => {};
