// @flow

import React from 'react';
import logo from '../../resources/img-logo.svg';
import FilterSectionComponent from './FilterSection';

import type { FiltersVisibility, FiltersState, FilterGroup } from '../../types';

import STYLES from './Filters.module.scss';

type Props = {
  locale: string,
  filtersVisibility: FiltersVisibility,
  filtersState: FiltersState,
  onClear: (FilterGroup) => void,
  onItemClick: (FilterGroup, string) => void,
  showLogo?: boolean,
  embedded?: boolean,
}

const Filters = (props: Props) => {
  const {
    locale, showLogo, embedded, filtersVisibility, filtersState, onClear, onItemClick,
  } = props;

  const sections = filtersVisibility
    .filter((section) => section.visible)
    .map((section) => (
      <FilterSectionComponent
        locale={locale}
        section={section}
        selectedItems={filtersState[section.id] || []}
        onClear={onClear}
        onItemClick={onItemClick}
        key={section.id}
      />
    ));

  const containerClasses = [STYLES.Container];
  if (!embedded) {
    containerClasses.push(STYLES.StickyContainer);
  }

  return (
    <div className={containerClasses.join(' ')}>
      { showLogo
        && <div className={STYLES.Section}><img className={STYLES.Logo} src={logo} alt="Logo" /></div>}
      { sections }
    </div>
  );
};

Filters.defaultProps = {
  showLogo: false,
  embedded: false,
};

export default Filters;
