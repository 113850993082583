// @flow

import React from 'react';
import STYLES from './Footer.module.scss';
import Link from '../link';
import { buildTranslate } from '../../services/strings/Localisation';

import logoImg from '../../resources/img-logo-white.svg';
import instagramImg from '../../resources/ic-instagram.svg';
import facebookImg from '../../resources/ic-facebook.svg';
import { LINKS } from '../../constants';

type Props = {
  locale: string,
  floating?: boolean
}

const Footer = (props: Props) => {
  const { floating, locale } = props;
  const t = buildTranslate(locale);
  const linksTag = 'Footer Link';
  return (
    <div className={`${STYLES.Container} ${floating ? STYLES.Floating : ''}`}>
      <Link
        href={t('Link home')}
        tag={linksTag}
        description="Home"
      >
        <img className={STYLES.Logo} src={logoImg} alt="Logo" />
      </Link>
      <ul className={STYLES.Links}>
        <li>
          <Link
            href={t('Link pricing')}
            tag={linksTag}
            description="Pricing"
          >
            {t('Footer pricing')}
          </Link>
        </li>
        <li>
          <Link
            href={t('Link contact')}
            tag={linksTag}
            description="Contact us"
          >
            {t('Footer contact')}
          </Link>
        </li>
        <li>
          <Link
            href={t('Link terms')}
            tag={linksTag}
            description="Terms and conditions"
            newTab
          >
            {t('Footer terms')}
          </Link>
        </li>
        <li>
          <Link
            href={t('Link privacy')}
            tag={linksTag}
            description="Privacy Policy"
            newTab
          >
            {t('Footer privacy')}
          </Link>
        </li>
      </ul>
      <ul className={STYLES.Social}>
        <li>
          <Link
            href={LINKS.instagram}
            tag={linksTag}
            description="Instagram"
            newTab
          >
            <img src={instagramImg} alt="Instagram" />
          </Link>
        </li>
        <li>
          <Link
            href={LINKS.facebook}
            tag={linksTag}
            description="Facebook"
            newTab
          >
            <img src={facebookImg} alt="Facebook" />
          </Link>
        </li>
      </ul>
    </div>
  );
};

Footer.defaultProps = {
  floating: false,
};

export default Footer;
