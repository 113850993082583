// @flow

import React from 'react';
import PrimaryButton from '../PrimaryButton';
import errorImg from '../../resources/img-error.svg';
import type { ErrorInfo as Props } from '../../types';

import STYLES from './ErrorSection.module.scss';

const ErrorSection = (props: Props) => {
  const {
    normalMsg, highlightMsg, buttonText, buttonLink, newTab = false,
  } = props;
  return (
    <div className={STYLES.Container}>
      <div className={STYLES.TextsContainer}>
        <p>{normalMsg}</p>
        <p className={STYLES.Highlight}>{highlightMsg}</p>
      </div>
      <img className={STYLES.Image} src={errorImg} alt="Error" />
      <PrimaryButton
        title={buttonText}
        link={{
          linkUrl: buttonLink,
          linkTag: 'Results Link',
          linkDescription: 'Error Button',
          newTab,
        }}
      />
    </div>
  );
};

export default ErrorSection;
