// @flow

import { Cookies } from 'react-cookie-consent';
import { INTERNAL_USER_COOKIE_NAME, INTERNAL_USER_QUERY_PARAM } from '../constants';
import { locationQueryParams } from '../services/queryParams';
import development from './development';
import productionInternal from './productionInternal';
import production from './production';
import type { Config } from '../types';


const isInternal = (): boolean => {
  const queryParams = locationQueryParams() || {};
  if (queryParams[INTERNAL_USER_QUERY_PARAM] === 'true') {
    Cookies.set(INTERNAL_USER_COOKIE_NAME, true);
    return true;
  }
  if (queryParams[INTERNAL_USER_QUERY_PARAM] === 'false') {
    Cookies.set(INTERNAL_USER_COOKIE_NAME, false);
    return false;
  }
  return Cookies.get(INTERNAL_USER_COOKIE_NAME) || false;
};

const getConfig = (): Config => {
  const environment = process.env.DLTB_NODE_ENV || process.env.NODE_ENV || 'development';

  const internal = isInternal();
  switch (environment) {
    case 'development': return development;
    default: return internal ? productionInternal : production;
  }
};

const config: Config = getConfig();

export default config;
