
// @flow

import React from 'react';
import { Helmet } from 'react-helmet';
import { getLocalized } from './strings/Localisation';

export type MetasPage = 'home' | 'results';

export type MetasProps = {
    page: MetasPage,
    locale: string,
    /* eslint-disable react/require-default-props */
    /* eslint-disable react/no-unused-prop-types */
    city?: string,
}

const getTitle = (props: MetasProps): string => {
  const { page, city = 'tu ciudad', locale } = props;
  switch (page) {
    case 'results':
      return getLocalized(locale, 'Results meta title', { city });
    case 'home':
    default:
      return getLocalized(locale, 'Home meta title');
  }
};

const getDescription = (props: MetasProps): string => {
  const { page, city = 'tu ciudad', locale } = props;
  switch (page) {
    case 'results':
      return getLocalized(locale, 'Results meta description', { city });
    case 'home':
    default:
      return getLocalized(locale, 'Home meta description');
  }
};

const getLang = (locale: string): string => {
  try {
    return locale.split('-')[0];
  } catch (error) {
    return 'es';
  }
};

const getCanonicalUrl = (page: MetasPage): ?string => {
  switch (page) {
    case 'results':
      return undefined;
    case 'home':
    default:
      return 'https://app.delitbee.com';
  }
};

const getRobotsContent = (page: MetasPage): string => {
  switch (page) {
    case 'results':
      return 'noindex, nofollow';
    case 'home':
    default:
      return 'index, follow';
  }
};

export const buildHelmet = (props: MetasProps) => {
  if (typeof window === 'undefined') {
    return null;
  }
  const { page, locale } = props;
  const currentUrl = window.location.href;
  const title = getTitle(props);
  const description = getDescription(props);
  const canonicalUrl = getCanonicalUrl(page);
  const robots = getRobotsContent(page);
  return (
    <Helmet>
      <html lang={getLang(locale)} />
      <title>{title}</title>
      <meta name="description" content={description} />
      { canonicalUrl
      && <link rel="canonical" href={canonicalUrl} />}
      <meta name="robots" content={robots} />
      <meta name="googlebot" content={robots} />
      <meta name="bingbot" content={robots} />
      <meta property="og:title" content={title} />
      <meta property="og:locale" content={locale.replace('-', '_')} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:description" content={description} />
    </Helmet>
  );
};

export const dummy = () => {};
