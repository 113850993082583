// @flow

import loadScript from './loadScript';

let hubsPotLoaded = false;

const load = () => {
  if (hubsPotLoaded) {
    return;
  }
  loadScript(
    'https://js.hs-scripts.com/7878347.js',
    () => {
      hubsPotLoaded = true;
    },
  );
};

export default { load };
