// @flow

import React, { Component } from 'react';
import PrimaryButton from '../PrimaryButton';
import LocationAutocomplete from './LocationAutocomplete';
import type { SearchLocation, LocationValidation, LocationData } from '../../types';
import { buildTranslate } from '../../services/strings/Localisation';
import { parseLineJumps } from '../utils/textUtils';
import STYLES from './Search.module.scss';

type Props = {
  locale: string,
  validateLocation: (location?: SearchLocation, locale: string) => Promise<LocationValidation>,
  bindAutocomplete: (textInputRef: { current: null | HTMLInputElement }, callback: (SearchLocation) => void) => void,
  onSearch: (location: LocationData) => void,
}

type State = {
  addressQuery: string,
  loading: boolean,
  selectedLocation?: LocationData,
  error?: string,
}

class Search extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      addressQuery: '',
      loading: false,
      selectedLocation: undefined,
      error: undefined,
    };
    this.onLocationChange = this.onLocationChange.bind(this);
  }

  onLocationChange = (location?: SearchLocation) => {
    if (location) {
      this.checkLocation(location);
    } else {
      this.clearLocation();
    }
  }

  clearLocation() {
    const { locale, validateLocation } = this.props;

    validateLocation(undefined, locale).then(() => {
      this.setState({
        addressQuery: '',
        error: undefined,
      });
    });
  }

  checkLocation(location: SearchLocation) {
    const { locale, validateLocation } = this.props;

    this.setState({
      addressQuery: location.formattedAddress,
      loading: true,
    });

    validateLocation(location, locale).then((validation) => {
      const { location: selectedLocation, error } = validation;
      this.setState({
        loading: false,
        selectedLocation,
        error,
      });
    });
  }

  render() {
    const { onSearch, bindAutocomplete, locale } = this.props;
    const {
      addressQuery, loading, error, selectedLocation,
    } = this.state;
    const t = buildTranslate(locale);
    return (
      <div className={STYLES.Form}>
        <LocationAutocomplete
          locale={locale}
          addressQuery={addressQuery}
          bindAutocomplete={bindAutocomplete}
          onLocation={this.onLocationChange}
        />
        { error
        && (
        <div className={STYLES.Error}>
          <p>{parseLineJumps(error)}</p>
        </div>
        )}
        <PrimaryButton
          title={t('Restaurant search button')}
          loading={loading}
          onClick={selectedLocation ? () => onSearch(selectedLocation) : undefined}
        />
      </div>
    );
  }
}

export default Search;
