// @flow

import * as React from 'react';
import STYLES from './Card.module.scss';

type Props = {
  sticky?: boolean,
  noPadding?: boolean,
  disclosure?: 'none' | 'icon' | 'info',
  label?: 'none' | 'closed',
  onClick?: () => void,
  children?: React.Node,
}

const Card = (props: Props) => {
  const {
    sticky, noPadding, children, onClick, disclosure, label,
  } = props;
  const styles = [STYLES.Card];
  if (sticky) {
    styles.push(STYLES.Sticky);
  }
  if (noPadding) {
    styles.push(STYLES.NoPadding);
  }
  if (onClick) {
    styles.push(STYLES.Clickable);
  }
  switch (disclosure) {
    case 'none':
      break;
    case 'info':
      if (onClick) styles.push(STYLES.InfoDisclosure);
      break;
    case 'icon':
    default:
      if (onClick) styles.push(STYLES.Disclosure);
  }
  if (label === 'closed') {
    styles.push(STYLES.Closed);
  }

  const clickProps = onClick ? {
    onClick,
    onKeyPress: onClick,
    role: 'button',
    tabIndex: '0',
  } : {};

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={styles.join(' ')} {...clickProps}>{children}</div>
  );
};

Card.defaultProps = {
  sticky: false,
  noPadding: false,
  disclosure: 'icon',
  onClick: undefined,
  label: 'none',
  children: undefined,
};

export default Card;
