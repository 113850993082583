// @flow

import React from 'react';
import Title from '../title';
import Link from '../link';
import { buildTranslate } from '../../services/strings/Localisation';
import { IMAGES, LINKS } from '../../constants';
import STYLES from './Claim.module.scss';

type Props = {
  locale: string,
}

const Claim = (props: Props) => {
  const { locale } = props;
  const t = buildTranslate(locale);
  return (
    <div className={STYLES.Container}>
      <p className={STYLES.Claim}>{t('Claim title')}</p>
      <h1 className={STYLES.MainText}>
        <b>{t('Claim subtitle')}</b>
      </h1>
      <h2 className={STYLES.MainText}>
        {t('Claim message')}
      </h2>
      <Title type="h2" text="Glovo | Deliveroo | UberEats | Just Eat" />
      <ul className={STYLES.Logos}>
        <li><img src={IMAGES.glovo} alt="Glovo" /></li>
        <li><img src={IMAGES.deliveroo} alt="Deliveroo" /></li>
        <li><img src={IMAGES.uberEats} alt="Uber Eats" /></li>
        <li><img src={IMAGES.justEat} alt="Justeat" /></li>
      </ul>
      <p className={STYLES.Supported}>{t('Home supported by')}</p>
      <Link
        href={LINKS.lanzadera}
        tag="Home link"
        description="Lanzadera"
        newTab
      >
        <img className={STYLES.Lanzadera} src={IMAGES.lanzadera} alt="Lanzadera" />
      </Link>
    </div>
  );
};

export default Claim;
