// @flow

import React from 'react';
import logo from '../../resources/img-logo.svg';
import STYLES from './Logo.module.scss';

const Logo = () => (
  <img className={STYLES.Main} src={logo} alt="Logo" />
);

export default Logo;
