// @flow

import React from 'react';
import Card from '../card';
import Link from '../link';
import { parseLineJumps } from '../utils/textUtils';
import imgFallback from '../../resources/img-restaurant-ph.svg';
import STYLES from './ListItem.module.scss';

import type { Props, ImgConfig, ListItemButton } from './types';

const getImgModifiers = (imgConfig: ImgConfig = {}): string => {
  const {
    squared = false, fee = false, speed = false,
  } = imgConfig;

  const imgModifiers = [];
  if (squared) {
    imgModifiers.push(STYLES.Squared);
  }
  if (fee) {
    imgModifiers.push(STYLES.Fee);
  }
  if (speed) {
    imgModifiers.push(STYLES.Speed);
  }
  return imgModifiers.join(' ');
};

const buildButton = (input: ListItemButton) => {
  const { onClick, text, img } = input;
  const classNames = [STYLES.Button];
  classNames.push(input.isCta ? STYLES.Primary : STYLES.Secondary);

  const clickWrapper = (event: Event) => {
    event.stopPropagation();
    onClick();
  };

  return (
    <div
      className={classNames.join(' ')}
      onClick={clickWrapper}
      onKeyPress={clickWrapper}
      role="button"
      tabIndex="0"
      key={input.isCta ? 'primary' : 'secondary'}
    >
      { img
      && <img src={img} alt="button icon" />}
      <p>{text}</p>
    </div>
  );
};

const buildButtons = (input: ?Array<ListItemButton>) => {
  if (!input || input.length === 0) return null;
  const buttons = input.map((b) => buildButton(b));
  return (
    <div className={STYLES.Buttons}>
      {buttons}
    </div>
  );
};

const ListItem = (props: Props) => {
  const {
    img, title, subtitle, subtitleHref, textIcon, buttons, onClick,
    icons = [], labels = [], boxText, imgConfig = {}, status, disclosure,
  } = props;

  const imgModifiers = getImgModifiers(imgConfig);
  const hasBody = labels.length > 0 || boxText;

  const onImageError = (ev: Event) => {
    const { target } = ev;
    if (target instanceof HTMLImageElement) {
      target.src = imgFallback;
    }
  };

  const buttonsContent = buildButtons(buttons);
  const headerClasses = [STYLES.Header];
  if (hasBody) {
    headerClasses.push(STYLES.Detail);
  }
  if (buttonsContent) {
    headerClasses.push(STYLES.WithButtons);
  }

  const subtitleText = <div className={`${STYLES.Subtitle} ${subtitleHref ? STYLES.Link : ''}`}>{subtitle}</div>;
  const subtitleContent = subtitleHref ? (
    <Link
      href={subtitleHref}
      tag="Restaurant Link"
      description="subtitle"
      newTab
    >
      {subtitleText}
    </Link>
  ) : subtitleText;

  return (
    <Card onClick={onClick} label={status} disclosure={disclosure}>
      <div className={headerClasses.join(' ')}>
        { img
        && (
        <div className={`${STYLES.Logo} ${imgModifiers}`}>
          <img onError={onImageError} src={img} alt="Logo" />
        </div>
        )}
        <div className={STYLES.Container}>
          <div className={STYLES.TitleWrapper}>
            <div className={STYLES.Title}>{title}</div>
            <div className={STYLES.Icons}>
              { icons.map((icon) => (<img src={icon} alt="Icon" key={icon} />))}
            </div>
          </div>
          {subtitleContent}
          { textIcon
          && (
          <div className={STYLES.TextIcon}>
            <img src={textIcon.icon} alt={textIcon.alt} />
            <p>{parseLineJumps(textIcon.text)}</p>
          </div>
          )}
        </div>
        { buttonsContent }
      </div>
      { hasBody
      && (
      <div className={STYLES.Body}>
        { labels.length > 0
        && (
        <div className={STYLES.Labels}>
          { labels.map((label) => (
            <div className={STYLES.Label} key={label.id}>
              <div className={STYLES.Highlight}>{label.highlight}</div>
              <p>{label.text}</p>
            </div>
          ))}
        </div>
        )}
        { boxText
        && (
        <div className={STYLES.Box}>
          <p>
            { parseLineJumps(boxText) }
          </p>
        </div>
        )}
      </div>
      )}
    </Card>
  );
};

ListItem.defaultProps = {
  img: undefined,
  subtitleHref: undefined,
  icons: [],
  labels: [],
  buttons: [],
  textIcon: undefined,
  onClick: undefined,
  boxText: undefined,
  imgConfig: undefined,
  status: 'none',
  disclosure: undefined,
};

export default ListItem;
