// @flow

import React from 'react';
import type { Location, RouterHistory, Match } from 'react-router-dom';
import { debounce } from 'underscore/modules';
import Analytics from '../../services/Analytics';
import Hubspot from '../../services/Hubspot';
import { buildHelmet } from '../../services/MetaUtils';
import Results from './Results';
import { apiErrorText } from '../../services/strings/TypeMapper';

import type {
  Restaurant, RestaurantsResult, DeliveryOption, FiltersVisibility, FiltersState, FilterGroup, ErrorInfo,
} from '../../types';

import mockRestaurants from '../../services/restaurants/MockRestaurants';
import {
  filterByNameOrFood, computeFiltersVisibility, toggleFilter, switchOffFilter, filterByFilterState, initialFilterState,
} from '../../services/restaurants/Filtering';
import { searchWithUrlParams } from '../../services/restaurants/restaurantsApi';

type Props = {
  locale: string,
  location: Location,
  history: RouterHistory,
  match: Match
};

type State = {
  textFilter: string,
  restaurants: Array<Restaurant>,
  visibleRestaurants: Array<Restaurant>,
  loading: boolean,
  filtersVisibility: FiltersVisibility,
  filtersState: FiltersState,
  filtersApplied: boolean,
  error?: ErrorInfo,
  city?: string,
}

const debouncedUserAction = debounce(Analytics.userAction, 300);

const getVisible = async (
  restaurants: Array<Restaurant>,
  query: string,
  filtersState: FiltersState,
  locale: string,
): Promise<Array<Restaurant>> => filterByNameOrFood(
  await filterByFilterState(restaurants, filtersState),
  query,
  locale,
);

const isDemo = (location: Location) => location.search.indexOf('demo=true') !== -1;

const getRestaurantId = (location: Location): ?string => {
  const parts = location.pathname.split('/');
  for (let i = 0; i < parts.length; i += 1) {
    if (parts[i] === 'results') {
      if (parts.length > i + 2) {
        return parts[i + 2];
      }
    }
  }
  return undefined;
};

const loadRestaurants = async (location: Location): Promise<RestaurantsResult> => searchWithUrlParams(location.search);

class ResultsController extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      textFilter: '',
      restaurants: [],
      visibleRestaurants: [],
      filtersVisibility: [],
      filtersState: {},
      loading: true,
      filtersApplied: false,
    };

    this.onTextFilter = this.onTextFilter.bind(this);
    this.onBackBtn = this.onBackBtn.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onDelivery = this.onDelivery.bind(this);
    this.onClearFilter = this.onClearFilter.bind(this);
    this.onFilterClick = this.onFilterClick.bind(this);
    this.onShowAll = this.onShowAll.bind(this);
    this.onEcommerceClick = this.onEcommerceClick.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.onPhoneClick = this.onPhoneClick.bind(this);
    this.onWebClick = this.onWebClick.bind(this);
  }

  async componentDidMount() {
    const { textFilter } = this.state;
    const {
      location, match, history, locale,
    } = this.props;
    const demoSearch = isDemo(location);
    if (!demoSearch) {
      Analytics.searchResultsStart();
    }

    const { restaurants, error, query } = demoSearch ? mockRestaurants() : await loadRestaurants(location);

    if (query && !demoSearch) {
      Analytics.searchResults({
        fullAddress: query.fullAddress,
        city: query.city,
      });
    }

    Hubspot.load();

    if (!match.isExact) {
      const restaurant = this.getSelectedRestaurant(restaurants);
      history.replace(`${match.url}${location.search}`);
      if (restaurant) {
        history.push(`${match.url}/${restaurant.id}${location.search}`);
      }
    }

    const filtersVisibility = await computeFiltersVisibility(restaurants, locale);
    const filtersState = initialFilterState(filtersVisibility);

    this.setState({
      restaurants,
      visibleRestaurants: await getVisible(restaurants, textFilter, filtersState, locale),
      filtersState,
      filtersVisibility,
      loading: false,
      error: error ? apiErrorText(error, locale) : undefined,
      city: query ? query.city : undefined,
    });
  }

  getSelectedRestaurant(restaurants: Array<Restaurant>): ?Restaurant {
    const { location } = this.props;
    const restId = getRestaurantId(location);
    if (restId) {
      const index = restaurants.map((r) => r.id).indexOf(restId);
      if (index !== -1) {
        return restaurants[index];
      }
    }
    return undefined;
  }

  onTextFilter = (text: string) => {
    const { locale } = this.props;
    const { restaurants, filtersState } = this.state;

    debouncedUserAction({
      name: 'Filter by text',
      evtParams: { text },
    });

    const noRecommended = switchOffFilter(filtersState, 'hidden', 'recommended');
    this.setState({
      textFilter: text,
      filtersState: noRecommended,
      filtersApplied: true,
    });

    getVisible(restaurants, text, noRecommended, locale)
      .then((visibleRestaurants) => this.setState({
        visibleRestaurants,
      }));
  }

  onSelect = (restaurant: Restaurant) => {
    const { location, history, match } = this.props;

    Analytics.navigation({
      name: 'restaurant-detail',
      source: 'results',
      destination: restaurant.name,
    });

    history.push(`${match.url}/${restaurant.id}${location.search}`);
  }

  onDelivery = (restaurant: Restaurant, delivery: DeliveryOption) => {
    const { city = 'undefined' } = this.state;
    Analytics.redirect({
      city,
      restaurant: restaurant.name,
      option: delivery.provider.id,
      url: delivery.deeplink,
    });
  }

  onBackBtn = () => {
    const { location, history } = this.props;
    Analytics.navigation({
      name: 'back',
      source: location.pathname,
    });
    history.goBack();
  }

  onClearFilter = (group: FilterGroup) => {
    const { locale } = this.props;
    const { restaurants, textFilter, filtersState } = this.state;
    Analytics.userAction({
      name: 'clear-filter-group',
      evtParams: { filter_group: group },
    });
    filtersState[group] = [];
    this.setState({
      filtersState,
    });

    getVisible(restaurants, textFilter, filtersState, locale)
      .then((visibleRestaurants) => this.setState({
        visibleRestaurants,
      }));
  }

  onFilterClick = (group: FilterGroup, filterId: string) => {
    const { locale } = this.props;
    const { restaurants, textFilter, filtersState } = this.state;
    Analytics.userAction({
      name: 'selected-filter',
      evtParams: {
        filter_group: group,
        filter_id: filterId,
      },
    });
    const noRecommended = switchOffFilter(filtersState, 'hidden', 'recommended');
    const newFiltersState = toggleFilter(noRecommended, group, filterId);
    this.setState({
      filtersState: newFiltersState,
      filtersApplied: true,
    });
    getVisible(restaurants, textFilter, newFiltersState, locale)
      .then((visibleRestaurants) => this.setState({
        visibleRestaurants,
      }));
  }

  onShowAll = (source: string) => {
    const { locale } = this.props;
    const { restaurants } = this.state;
    Analytics.userAction({
      name: 'show-all-results',
      evtParams: { source },
    });
    this.setState({
      filtersState: {},
      filtersApplied: true,
      textFilter: '',
    });
    getVisible(restaurants, '', {}, locale)
      .then((visibleRestaurants) => this.setState({
        visibleRestaurants,
      }));
  }

  onMenuClick = (restaurant: Restaurant) => {
    const { city = 'undefined' } = this.state;
    const { mainDelivery } = restaurant;
    if (!mainDelivery || !mainDelivery.menuLink) return;
    Analytics.redirect({
      city,
      restaurant: restaurant.name,
      option: 'direct-menu',
      url: mainDelivery.menuLink,
      storeId: mainDelivery.id,
    });
  }

  onPhoneClick = (restaurant: Restaurant) => {
    const { city = 'undefined' } = this.state;
    const { mainDelivery } = restaurant;
    if (!mainDelivery || !mainDelivery.phoneNumber) return;
    Analytics.redirect({
      city,
      restaurant: restaurant.name,
      option: 'direct-menu',
      url: `tel:${mainDelivery.phoneNumber}`,
      storeId: mainDelivery.id,
      newTab: false,
    });
  }

  onWebClick = (restaurant: Restaurant) => {
    const { city = 'undefined' } = this.state;
    const { mainDelivery } = restaurant;
    if (!mainDelivery || !mainDelivery.webLink) return;
    Analytics.redirect({
      city,
      restaurant: restaurant.name,
      option: 'direct-web',
      url: mainDelivery.webLink,
      storeId: mainDelivery.id,
    });
  }

  onEcommerceClick = (restaurant: Restaurant) => {
    const { city = 'undefined' } = this.state;
    const { mainDelivery } = restaurant;
    if (!mainDelivery || !mainDelivery.ecommerceLink) return;
    Analytics.redirect({
      city,
      restaurant: restaurant.name,
      option: 'direct-ecommerce',
      url: mainDelivery.ecommerceLink,
      storeId: mainDelivery.id,
    });
  }

  render() {
    const { locale } = this.props;
    const {
      filtersVisibility, filtersState, textFilter, visibleRestaurants, loading, error, restaurants, city,
      filtersApplied,
    } = this.state;
    const selectedRestaurant = this.getSelectedRestaurant(restaurants);
    return (
      <>
        {buildHelmet({
          page: 'results',
          locale,
          city,
        })}
        <Results
          locale={locale}
          textFilter={textFilter}
          restaurants={visibleRestaurants}
          totalRestaurants={restaurants.length}
          showMoreButton={!filtersApplied && visibleRestaurants.length < restaurants.length}
          loading={loading}
          filtersVisibility={filtersVisibility}
          filtersState={filtersState}
          error={error}
          selectedRestaurant={selectedRestaurant ?? undefined}
          onTextFilter={this.onTextFilter}
          onSelect={this.onSelect}
          onDelivery={this.onDelivery}
          onBackBtn={this.onBackBtn}
          onClearFilter={this.onClearFilter}
          onFilterClick={this.onFilterClick}
          onShowAll={this.onShowAll}
          onEcommerceClick={this.onEcommerceClick}
          onMenuClick={this.onMenuClick}
          onPhoneClick={this.onPhoneClick}
          onWebClick={this.onWebClick}
        />
      </>
    );
  }
}

export default ResultsController;
