// @flow

import { UNKNOWN_FEE_VALUE } from '../../constants';
import imgFallback from '../../resources/img-restaurant-ph.svg';
import type {
  Restaurant, DeliveryOption, RestaurantDelivery, ResultQuery, Provider,
} from '../../types';


const mapPrice = (source: any): number => {
  if (source !== null && source !== undefined) {
    if (typeof source === 'number') {
      return source;
    }
    return source.amount;
  }
  return UNKNOWN_FEE_VALUE;
};

const mapServiceFee = (source: any) => {
  if (source !== null && source !== undefined) {
    const amount = source.amount.toFixed(2);
    if (source.type && source.type === 'percent') {
      return `${amount * 100} %`;
    }
    return `${amount} €`;
  }
  return undefined;
};

const mapEtaRange = (source: any) => {
  if (source !== null && source !== undefined) {
    if (source.live === false) { // TODO IMPLEMENT INFO
      return undefined;
    }
    return {
      min: source.min,
      max: source.max,
    };
  }
  return undefined;
};

const mapRestaurantDelivery = (source: any): RestaurantDelivery => {
  const deliveryFee: number = mapPrice(source.delivery_fee);
  const minBasket: number = mapPrice(source.min_basket);
  const deliveryTime = mapEtaRange(source.eta_range);
  const address = source.contact_info ? source.contact_info.address : undefined;
  const phoneNumber = source.contact_info ? source.contact_info.phone : undefined;
  return {
    id: source.id,
    open: source.open,
    address,
    phoneNumber,
    delivery: source.delivery || false,
    collection: source.collection || false,
    offer: source.offer,
    timeTable: source.time_table,
    webLink: source.links.web,
    menuLink: source.links.menu,
    mapLink: source.links.map,
    ecommerceLink: source.links.ecommerce,
    deliveryFee,
    minBasket,
    deliveryTime,
  };
};

const mapDelivery = (delivery: any, providers: { [key: string]: Provider }): DeliveryOption => {
  const deliveryFee: number = mapPrice(delivery.delivery_fee);
  const serviceFee = mapServiceFee(delivery.service_fee);
  const minBasket: number = mapPrice(delivery.min_basket);
  const deliveryFeeThreshold = delivery.delivery_fee_threshold ? {
    price: delivery.delivery_fee_threshold.fee ? mapPrice(delivery.delivery_fee_threshold.fee) : undefined,
    threshold: mapPrice(delivery.delivery_fee_threshold.threshold),
  } : undefined;
  const deliveryTime = mapEtaRange(delivery.eta_range);
  const discount = delivery.discount ? delivery.discount.claim : undefined;
  const contactInfo = delivery.contact_info ? {
    address: delivery.contact_info.address,
    phoneNumber: delivery.contact_info.phone,
  } : undefined;
  const provider = providers[delivery.provider_id] || { id: 'unknown', name: 'Unknown' };
  return {
    provider,
    open: delivery.open,
    delivery: delivery.delivery || false,
    collection: delivery.collection || false,
    deeplink: delivery.deeplink,
    discount,
    deliveryTime,
    deliveryFee,
    serviceFee,
    deliveryFeeThreshold,
    minBasket,
    contactInfo,
  };
};

export const mapRestaurant = (restaurant: any, providers: any): Restaurant => {
  const deliveries = restaurant.deliveries
    ? restaurant.deliveries.map((delivery) => mapDelivery(delivery, providers)) : [];
  const restaurantDelivery = restaurant.main_delivery ? mapRestaurantDelivery(restaurant.main_delivery) : undefined;
  return {
    internalId: restaurant.id,
    id: restaurant.slug,
    name: restaurant.name,
    icon: restaurant.logo || imgFallback,
    foods: restaurant.food_types || ['other'],
    delivery: restaurant.delivery || false,
    collection: restaurant.collection || false,
    offers: restaurant.offers || false,
    recommended: restaurant.recommended || false,
    deliveries,
    mainDelivery: restaurantDelivery,
  };
};

export const mapRestaurants = (apiResponse: any): Array<Restaurant> => {
  if (!apiResponse.restaurants || !apiResponse.providers) {
    return [];
  }
  return apiResponse.restaurants
    .map((r) => mapRestaurant(r, apiResponse.providers));
};

export const mapQuery = (apiResponse: any): ResultQuery => {
  const { query } = apiResponse;
  return {
    city: query.location.city,
    locale: query.locale,
    fullAddress: query.location.full_address,
  };
};
