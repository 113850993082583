// @flow

import i18n from '../../resources/i18n-source.json';
import Logger from '../Logger';


const normalizeLocale = (locale: string): string => {
  try {
    if (locale in i18n) {
      return locale;
    }
    const language = locale.split('-')[0];
    if (language in i18n) {
      return language;
    }
  } catch (e) {
    Logger.exception('error normalizing locale', e);
  }
  return 'es';
};

export const getLocalized = (
  locale: string,
  key: string,
  replacements?: { [key: string]: string | number },
): string => {
  const normLocale = normalizeLocale(locale);
  let result: string = i18n[normLocale][key];
  if (replacements) {
    const keys = Object.keys(replacements);
    for (let i = 0, { length } = keys; i < length; i += 1) {
      result = result.replace(`{{${keys[i]}}}`, `${replacements[keys[i]]}`);
    }
  }
  return result;
};

export const buildTranslate = (locale: string) => (
  key: string,
  replacements?: { [key: string]: string | number },
): string => getLocalized(locale, key, replacements);
