/* eslint-disable max-len */
// @flow

import React from 'react';

import {
  Switch,
  Route,
} from 'react-router-dom';
import Home from './routes/home';
import Results from './routes/results';
import EmbeddedSearch from './routes/EmbeddedSearch';

type Props = {
  locale: string,
}

const localeRegex = '(es|es-ES|es-es|en|en-GB|en-gb)';

const App = (props: Props) => {
  const { locale } = props;
  return (
    <>
      <Switch>
        <Route path={`/:locale${localeRegex}/embedded-search`} render={({ match }) => <EmbeddedSearch locale={match.params.locale || locale} />} />
        <Route path="/embedded-search" render={() => <EmbeddedSearch locale={locale} />} />
        <Route path={`/:locale${localeRegex}/results/:slug`} render={({ location, match, history }) => <Results locale={match.params.locale || locale} location={location} history={history} match={match} />} />
        <Route path="/results/:slug" render={({ location, match, history }) => <Results locale={locale} location={location} history={history} match={match} />} />
        <Route path={`/:locale${localeRegex}`} render={({ match, history }) => <Home locale={match.params.locale || locale} searchStyle="normal" match={match} history={history} />} />
        <Route path="/" render={({ match, history }) => <Home locale={locale} searchStyle="normal" match={match} history={history} />} />
      </Switch>
    </>
  );
};

export default App;
