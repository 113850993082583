// @flow

import React from 'react';
import Breakpoint, { BREAKPOINTS } from '../breakpoint';
import STYLES from './ResultsCounter.module.scss';
import { buildTranslate } from '../../services/strings/Localisation';

type Props = {
    locale: string,
    current: number,
    total: number,
    onShowAll: () => void,
}

const ResultsCounter = (props: Props) => {
  const {
    current, total, onShowAll, locale,
  } = props;
  const t = buildTranslate(locale);

  return (
    <div className={STYLES.Container}>
      <Breakpoint query={BREAKPOINTS.MOBILE}>
        { (isActive) => (isActive
          ? <div className={STYLES.Text}>{t('Results counter mobile', { current, total })}</div>
          : <div className={STYLES.Text}>{t('Results counter full', { current, total })}</div>)}
      </Breakpoint>

      { current < total
      && (
      <div
        className={STYLES.Link}
        role="button"
        onClick={onShowAll}
        onKeyPress={onShowAll}
        tabIndex="0"
      >
        {t('Results counter see all')}
      </div>
      )}
    </div>
  );
};

export default ResultsCounter;
