// @flow

import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { CONSENT_COOKIE_NAME } from '../../constants';
import Analytics from '../../services/Analytics';
import STYLES from './CookieBanner.module.scss';
import { buildTranslate } from '../../services/strings/Localisation';

type Props = {
  locale: string,
}

const getDomainName = () => {
  if (typeof window === 'undefined') {
    return 'delitbee.com';
  }
  try {
    let url = window.location.origin;
    url = url.replace(/(https?:\/\/)?(www.)?/i, '');
    url = url.split('.');
    url = url.slice(url.length - 2).join('.');
    if (url.indexOf('/') !== -1) {
      return url.split('/')[0];
    }
    if (url.indexOf(':') !== -1) {
      return url.split(':')[0];
    }
    return url;
  } catch (e) {
    return 'delitbee.com';
  }
};

const onAccept = () => {
  Analytics.setConsent(true);
};

const onDecline = () => {
  Analytics.setConsent(false);
};

const CookieBanner = (props: Props) => {
  const { locale } = props;
  const t = buildTranslate(locale);
  const buttonStyle = {
    'border-radius': '4px',
    padding: '10px 15px',
  };
  return (
    <CookieConsent
      location="bottom"
      buttonText={t('Accept')}
      enableDeclineButton
      declineButtonText={t('Decline')}
      cookieName={CONSENT_COOKIE_NAME}
      onAccept={onAccept}
      onDecline={onDecline}
      style={{
        'align-items': 'center', background: '#353e4a', 'flex-wrap': 'nowrap', 'flex-direction': 'column',
      }}
      contentStyle={{ flex: 'auto' }}
      buttonStyle={buttonStyle}
      declineButtonStyle={buttonStyle}
      contentClasses={STYLES.Content}
      buttonWrapperClasses={STYLES.Buttons}
      extraCookieOptions={{ domain: getDomainName() }}
    >
      <p>
        {t('Cookie banner text')}
      </p>
    </CookieConsent>
  );
};

export default CookieBanner;
