// @flow

import React from 'react';
import STYLES from './Switch.module.scss';

type Props = {
  selected: boolean,
  onClick: () => void,
}

const Switch = (props: Props) => {
  const {
    selected, onClick,
  } = props;

  return (
    <div
      className={STYLES.Switch}
      role="button"
      onClick={onClick}
      onKeyPress={onClick}
      tabIndex="0"
    >
      <input type="checkbox" checked={selected} readOnly />
      <span className="slider round" />
    </div>
  );
};

export default Switch;
