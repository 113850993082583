// @flow

import { UNKNOWN_FEE_VALUE } from '../../constants';
import { buildTranslate } from '../../services/strings/Localisation';
import type { Label } from '../listitem/types';
import type {
  DeliveryOption, RestaurantDelivery, TimeRange, PriceThreshold,
} from '../../types';

type LabelsOptions = {
    locale: string,
    deliveryTime?: TimeRange,
    deliveryFee: number,
    serviceFee?: string,
    deliveryFeeThreshold?: PriceThreshold,
    minBasket: number,
}

const buildLabels = (delivery: LabelsOptions) => {
  const {
    locale, deliveryFee, serviceFee, deliveryFeeThreshold, minBasket = 0, deliveryTime,
  } = delivery;
  const t = buildTranslate(locale);

  const labels: Array<Label> = [];

  if (deliveryFee !== UNKNOWN_FEE_VALUE) {
    labels.push({
      id: 'delivery_fee',
      highlight: deliveryFee > 0 ? `${deliveryFee.toFixed(2)} €` : t('Price free'),
      text: t('Detail delivery charge'),
    });
  }

  if (serviceFee) {
    labels.push({
      id: 'service_fee',
      highlight: `+${serviceFee}`,
      text: t('Detail service fee'),
    });
  }

  if (deliveryFeeThreshold) {
    const { price, threshold } = deliveryFeeThreshold;
    labels.push({
      id: 'fee_threshold',
      highlight: price ? `+${price.toFixed(2)} €` : t('Price surcharge'),
      text: t('Detail order less than', { amount: `${threshold.toFixed(2)} €` }),
    });
  }

  if (deliveryTime) {
    const value = deliveryTime.min !== deliveryTime.max
      ? `${deliveryTime.min}-${deliveryTime.max} min`
      : `${deliveryTime.min} min`;
    labels.push({
      id: 'time',
      highlight: value,
      text: t('Detail delivery time'),
    });
  }

  if (minBasket !== UNKNOWN_FEE_VALUE) {
    labels.push({
      id: 'minbasket',
      highlight: minBasket > 0 ? `${minBasket.toFixed(2)} €` : '0 €',
      text: minBasket > 0 ? t('Detail min basket') : t('Detail no min basket'),
    });
  }
  return labels;
};

export const deliveryOptionLabels = (delivery: DeliveryOption, locale: string) => {
  const {
    deliveryFee, serviceFee, deliveryFeeThreshold, minBasket = 0, deliveryTime,
  } = delivery;

  return buildLabels({
    locale, serviceFee, deliveryFee, deliveryFeeThreshold, minBasket, deliveryTime,
  });
};

export const restaurantDeliveryLabels = (delivery: RestaurantDelivery, locale: string) => {
  const {
    deliveryFee, minBasket = 0, deliveryTime,
  } = delivery;

  return buildLabels({
    locale, deliveryFee, minBasket, deliveryTime,
  });
};

export const Dummy = () => {};
