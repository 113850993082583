// @flow

import React from 'react';
import ListItem from '../listitem';
import { foodTexts } from '../../services/strings/TypeMapper';

import type { Restaurant } from '../../types';


type Props = {
    locale: string,
    restaurant: Restaurant,
    onClick?: () => void,
}

const RestaurantHeader = (props: Props) => {
  const { restaurant, onClick, locale } = props;
  const {
    name, foods, icon,
  } = restaurant;

  return (
    <ListItem
      img={icon}
      title={name}
      subtitle={foodTexts(foods, locale)}
      onClick={onClick}
      disclosure="info"
    />
  );
};

RestaurantHeader.defaultProps = {
  onClick: undefined,
};

export default RestaurantHeader;
