// @flow

import { Base64 } from 'js-base64';
import loadScript from './loadScript';
import Logger from './Logger';
import { LOCATIONS_PATH, LINKS } from '../constants';
import config from '../config';
import measuredFetch from './measuredFetch';
import Analytics from './Analytics';
import { locationSearch } from './queryParams';
import { getLocalized } from './strings/Localisation';
import type {
  SearchLocation, LocationData, ResultsUrl, LocationValidation,
} from '../types';

const encodeLocation = (location: SearchLocation): string => {
  const stringified = JSON.stringify(location);
  return Base64.encode(stringified);
};

const decodeLocation = (location: string): SearchLocation => {
  const decodedString = Base64.decode(location);
  return JSON.parse(decodedString);
};

const storeSelection = (location: SearchLocation) => {
  try {
    const encoded = encodeLocation(location);
    localStorage.setItem('last-loc', encoded);
  } catch (e) {
    Logger.exception('Error when storing location', e);
  }
};

const retrieveLastSelection = (): ?SearchLocation => {
  try {
    const encoded = localStorage.getItem('last-loc');
    if (encoded) {
      const decoded = decodeLocation(encoded);
      // throwing out old types of stored locations
      if (!decoded.sourceJson || typeof decoded.sourceJson === 'string') {
        return undefined;
      }
      return decoded;
    }
  } catch (e) {
    Logger.exception('Error when retrieving stored location', e);
  }
  return undefined;
};

const parseGooglePlace = (place: any): SearchLocation => {
  let countryCode = '';
  let postCode = '';
  let locality = '';
  let city = '';
  place.address_components.forEach((element) => {
    if (element.types.indexOf('locality') !== -1) {
      locality = element.long_name;
    } else if (element.types.indexOf('administrative_area_level_2') !== -1) {
      city = element.long_name;
    } else if (element.types.indexOf('country') !== -1) {
      countryCode = element.short_name;
    } else if (element.types.indexOf('postal_code') !== -1) {
      postCode = element.short_name;
    }
  });

  return {
    id: place.place_id,
    latitude: place.geometry.location.lat(),
    longitude: place.geometry.location.lng(),
    formattedAddress: place.formatted_address,
    countryCode,
    postCode,
    locality,
    city,
    sourceJson: { google: place },
  };
};

const handleGooglePlaceSelect = async (placeObject: any, callback: (SearchLocation) => void) => {
  if (!placeObject.address_components) {
    return;
  }
  const location = parseGooglePlace(placeObject);

  storeSelection(location);

  callback(location);
};

const bindGoogleAutocomplete = (
  textInputRef: { current: null | HTMLInputElement },
  callback: (SearchLocation) => void,
) => {
  const autoComplete = new window.google.maps.places.Autocomplete(
    textInputRef.current,
    { types: ['address'], componentRestrictions: { country: 'es' } },
  );
  autoComplete.setFields(['address_components', 'formatted_address', 'geometry.location', 'place_id']);
  autoComplete.addListener('place_changed', () => handleGooglePlaceSelect(autoComplete.getPlace(), callback));
};

export const bindAutocomplete = (
  textInputRef: { current: null | HTMLInputElement },
  callback: (SearchLocation) => void,
) => {
  const previous = retrieveLastSelection();
  if (previous) {
    callback(previous);
  }

  const apiKey = config.googleApiKey || process.env.REACT_APP_DELITBEE_GOOGLE_API_KEY;

  if (!apiKey) {
    return;
  }

  if (!window.google) {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&language=es-ES`,
      () => bindGoogleAutocomplete(textInputRef, callback),
    );
  } else {
    bindGoogleAutocomplete(textInputRef, callback);
  }
};

const buildErrorLocation = (): LocationData => ({
  status: 'invalid',
  slug: '',
  full_address: '',
});

export const checkLocation = async (
  location: SearchLocation,
  locale: string,
  allCities: boolean): Promise<LocationData> => {
  const finalUrl = `${config.apiHost}${LOCATIONS_PATH}?locale=${locale}&version=1`;
  const body = location.sourceJson;
  if (allCities) {
    body.include_not_ready = true;
  }
  let response;
  try {
    response = await measuredFetch({
      url: finalUrl,
      tag: 'location-check',
      method: 'POST',
      body: JSON.stringify(body),
    });
  } catch (e) {
    Logger.exception('error checkign location', e);
    return buildErrorLocation();
  }
  if (response.ok) {
    try {
      const locationResponse = await response.json();
      return {
        ...locationResponse,
        results_url: `/${locale}/results/${locationResponse.slug}?loc=${locationResponse.id}`,
      };
    } catch (e) {
      Logger.exception('error parsing locationdata', e);
    }
  }
  Logger.error('location response not ok');
  return buildErrorLocation();
};

export const buildLinkUrl = async (
  location?: SearchLocation,
  locale: string,
  allCities?: boolean): Promise<ResultsUrl> => {
  if (!location) {
    return {
      error: { message: getLocalized(locale, 'Search invalid location') },
    };
  }
  Analytics.userAction({
    name: 'selected-location',
    evtParams: {
      city: location.city,
      type: 'main',
    },
  });
  const locationData = await checkLocation(location, locale, allCities || false);
  if (locationData.status === 'ok') {
    // Adding existing query params
    const queryParams = locationSearch();
    const appendParams = queryParams.length > 0 ? `&${queryParams}` : '';
    return { linkUrl: `${locationData.results_url || ''}${appendParams}` };
  } if (locationData.status === 'not_ready') {
    return {
      error: {
        message: getLocalized(locale, 'Search city not ready', { city: location.city }),
        linkMsg: getLocalized(locale, 'Search city not ready link'),
        linkUrl: LINKS.facebook,
      },
    };
  }
  return {
    error: { message: getLocalized(locale, 'Search city missing', { city: location.city }) },
  };
};

export const validateLocation = async (
  location?: SearchLocation,
  locale: string,
  allCities?: boolean): Promise<LocationValidation> => {
  if (!location) {
    return {
      error: getLocalized(locale, 'Search invalid location'),
    };
  }
  Analytics.userAction({
    name: 'selected-location',
    evtParams: {
      city: location.city,
      type: 'restaurant-space',
    },
  });
  const locationData = await checkLocation(location, locale, allCities || false);
  if (locationData.status === 'ok') {
    return { location: locationData };
  } if (locationData.status === 'not_ready') {
    return {
      error: getLocalized(locale, 'Search city not ready', { city: location.city }),
    };
  }
  return {
    error: getLocalized(locale, 'Search city missing', { city: location.city }),
  };
};
