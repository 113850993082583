// @flow

import React from 'react';
import FilterItemComponent from './FilterItem';
import { getLocalized } from '../../services/strings/Localisation';
import type { FilterSection, FilterGroup } from '../../types';

import STYLES from './Filters.module.scss';

type Props = {
  locale: string,
  section: FilterSection,
  selectedItems: Array<string>,
  onClear: (FilterGroup) => void,
  onItemClick: (FilterGroup, string) => void,
}

const FilterSectionComponent = (props: Props) => {
  const {
    locale, section, selectedItems, onClear, onItemClick,
  } = props;

  const items = section.items.map((item) => {
    const selected = selectedItems.indexOf(item.id) !== -1;
    return (
      <FilterItemComponent
        item={item}
        selected={selected}
        onClick={(itemId) => onItemClick(section.id, itemId)}
        key={item.id}
      />
    );
  });

  return (
    <div className={STYLES.Section}>
      <div className={STYLES.Row}>
        <div className={STYLES.TitleText}>{section.text}</div>
        <div
          className={STYLES.BtnClean}
          role="button"
          onClick={() => onClear(section.id)}
          onKeyPress={() => onClear(section.id)}
          tabIndex="0"
        >
          {getLocalized(locale, 'Filters clear')}
        </div>
      </div>
      {items}
    </div>
  );
};

export default FilterSectionComponent;
