// @flow

import React from 'react';
import { getLocalized } from '../../services/strings/Localisation';
import logoImg from '../../resources/img-logo-white.svg';
import STYLES from './Navbar.module.scss';

type NavbarButton = {
  text: string,
  onClick: () => void,
}

type Props = {
    locale: string,
    onBack?: () => void,
    onlyTablet?: boolean,
    hideLogo?: boolean,
    leftButton?: NavbarButton,
    rightButton?: NavbarButton,
    titleText?: string,
}

const buildButton = (button?: NavbarButton, classNames: Array<String> = []) => {
  if (!button) {
    return null;
  }
  const buttonClassNames = [STYLES.Btn];
  classNames.forEach((cn) => buttonClassNames.push(cn));
  return (
    <div
      role="button"
      className={buttonClassNames.join(' ')}
      onClick={button.onClick}
      onKeyPress={button.onClick}
      tabIndex="0"
    >
      {button.text}
    </div>
  );
};

const buildLeftButton = (locale: string, onBack?: () => void, leftButton?: NavbarButton) => {
  if (onBack) {
    return buildButton({ onClick: onBack, text: getLocalized(locale, 'Back') }, [STYLES.Back]);
  } if (leftButton) {
    return buildButton(leftButton);
  }
  return <div />;
};

const buildTitle = (titleText?: string, hideLogo: boolean) => {
  if (titleText) {
    return (
      <div className={STYLES.Title}>
        {titleText}
      </div>
    );
  }
  if (!hideLogo) {
    return <img className={STYLES.Logo} src={logoImg} alt="Logo" />;
  }
  return <div />;
};

const Navbar = (props: Props) => {
  const {
    locale, onBack, onlyTablet, hideLogo = false, leftButton, rightButton, titleText,
  } = props;

  const navbarClasses = [STYLES.Navbar];
  if (onlyTablet) {
    navbarClasses.push(STYLES.OnlyTablet);
  }

  return (
    <div className={navbarClasses.join(' ')}>
      <div className={STYLES.Container}>
        {buildLeftButton(locale, onBack, leftButton)}
        {buildTitle(titleText, hideLogo)}
        <div>
          {buildButton(rightButton)}
        </div>
      </div>
    </div>
  );
};

Navbar.defaultProps = {
  onBack: undefined,
  onlyTablet: false,
  hideLogo: false,
  leftButton: undefined,
  rightButton: undefined,
  titleText: undefined,
};

export default Navbar;
