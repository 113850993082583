// @flow

import React, { Component } from 'react';
import PrimaryButton from '../PrimaryButton';
import Link from '../link';
import LocationAutocomplete from './LocationAutocomplete';
import type { SearchLocation, ResultsUrl, ResultsUrlError } from '../../types';
import { buildTranslate } from '../../services/strings/Localisation';
import { parseLineJumps } from '../utils/textUtils';
import STYLES from './Search.module.scss';

type Props = {
  locale: string,
  buildLinkUrl: (location?: SearchLocation, locale: string) => Promise<ResultsUrl>,
  bindAutocomplete: (textInputRef: { current: null | HTMLInputElement }, callback: (SearchLocation) => void) => void,
  onClick: () => void,
  newTab?: boolean,
}

type State = {
  addressQuery: string,
  loading: boolean,
  error?: ResultsUrlError,
  linkUrl?: string,
}

class Search extends Component<Props, State> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    newTab: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      addressQuery: '',
      loading: false,
      linkUrl: undefined,
      error: undefined,
    };
    this.onLocationChange = this.onLocationChange.bind(this);
  }

  onLocationChange = (location?: SearchLocation) => {
    if (location) {
      this.checkLocation(location);
    } else {
      this.clearLocation();
    }
  }

  clearLocation() {
    const { locale, buildLinkUrl } = this.props;

    buildLinkUrl(undefined, locale).then(() => {
      this.setState({
        addressQuery: '',
        linkUrl: undefined,
      });
    });
  }

  checkLocation(location: SearchLocation) {
    const { locale, buildLinkUrl } = this.props;

    this.setState({
      addressQuery: location.formattedAddress,
      loading: true,
    });

    buildLinkUrl(location, locale).then((resultsUrl) => {
      const { linkUrl = '', error } = resultsUrl;
      this.setState({
        linkUrl,
        loading: false,
        error,
      });
    });
  }

  render() {
    const {
      newTab, onClick, bindAutocomplete, locale,
    } = this.props;
    const {
      addressQuery, linkUrl, loading, error,
    } = this.state;
    const t = buildTranslate(locale);
    const link = linkUrl ? {
      newTab,
      linkUrl,
      linkTag: 'Home Link',
      linkDescription: 'Search Button',
      onClick,
    } : undefined;
    return (
      <div className={STYLES.Form}>
        <LocationAutocomplete
          locale={locale}
          addressQuery={addressQuery}
          bindAutocomplete={bindAutocomplete}
          onLocation={this.onLocationChange}
        />
        { error
        && (
        <div className={STYLES.Error}>
          <p>{parseLineJumps(error.message)}</p>
          {error.linkMsg && error.linkUrl
            && (
            <Link
              href={error.linkUrl}
              tag="Home link"
              description="Not Ready City"
              newTab
            >
              {error.linkMsg}
            </Link>
            )}
        </div>
        )}
        <PrimaryButton
          title={t('Search button')}
          loading={loading}
          link={link}
        />
      </div>
    );
  }
}

export default Search;
