// @flow

import * as React from 'react';
import MediaQuery from 'react-responsive';
import TOKENS from './Common';

const BREAKPOINTS = {
  MOBILE: TOKENS.breakpointQueryMobile,
  TABLET: TOKENS.breakpointQueryTablet,
  TABLET_ONLY: TOKENS.breakpointQueryTabletOnly,
  ABOVE_MOBILE: TOKENS.breakpointQueryAboveMobile,
  ABOVE_TABLET: TOKENS.breakpointQueryAboveTablet,
};

type Props = {
  query: string,
  children: React.Node | (boolean) => React.Node,
};

const Breakpoint = (props: Props) => {
  const { query, children } = props;
  return (<MediaQuery query={query}>{children}</MediaQuery>);
};

export { BREAKPOINTS };
export default Breakpoint;
