// @flow

import React from 'react';
import ListItem from '../listitem';
import promoImg from '../../resources/ic-promo.svg';
import pickupImg from '../../resources/ic-pickup.svg';
import deliveryImg from '../../resources/ic-delivery.svg';
import { foodTexts } from '../../services/strings/TypeMapper';

import type { Restaurant } from '../../types';

type Props = {
    locale: string,
    restaurant: Restaurant,
    onClick: () => void,
}

const RestaurantRow = (props: Props) => {
  const { restaurant, onClick, locale } = props;
  const {
    name, foods, icon, delivery, collection, offers,
  } = restaurant;

  const icons = [];
  if (offers) {
    icons.push(promoImg);
  }
  if (delivery) {
    icons.push(deliveryImg);
  }
  if (collection) {
    icons.push(pickupImg);
  }

  return (
    <ListItem
      img={icon}
      title={name}
      subtitle={foodTexts(foods, locale)}
      onClick={onClick}
      icons={icons}
    />
  );
};

export default RestaurantRow;
