// @flow

import React, { Component, createRef } from 'react';
import { buildTranslate } from '../../services/strings/Localisation';
import type { SearchLocation } from '../../types';
import STYLES from './Search.module.scss';

type Props = {
  locale: string,
  addressQuery: string,
  bindAutocomplete: (textInputRef: { current: null | HTMLInputElement }, callback: (SearchLocation) => void) => void,
  onLocation: (location?: SearchLocation) => void,
}

type State = {
    addressQuery: string,
}

class LocationAutocomplete extends Component<Props, State> {
  textInputRef: { current: null | HTMLInputElement };

  autoComplete: any;

  constructor(props: Props) {
    super(props);
    const { addressQuery } = this.props;
    this.state = {
      addressQuery,
    };

    this.textInputRef = createRef();
    this.autoComplete = null;
  }

  componentDidMount() {
    const { bindAutocomplete, onLocation } = this.props;
    bindAutocomplete(this.textInputRef, (location) => {
      onLocation(location);
    });
  }

  componentDidUpdate(prevProps: Props) {
    const { addressQuery: prevAddressQuery } = prevProps;
    const { addressQuery } = this.props;
    // Typical usage (don't forget to compare props):
    if (prevAddressQuery !== addressQuery) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ addressQuery });
    }
  }

  onSetText = (text: string) => {
    this.setState({ addressQuery: text });
    if (!text) {
      const { onLocation } = this.props;
      onLocation();
    }
  }

  render() {
    const { locale } = this.props;
    const { addressQuery } = this.state;
    const t = buildTranslate(locale);
    return (
      <div className={STYLES.Text}>
        <input
          type="search"
          placeholder={t('Search placeholder')}
          onChange={(e) => this.onSetText(e.target.value)}
          ref={this.textInputRef}
          value={addressQuery}
        />
      </div>
    );
  }
}

export default LocationAutocomplete;
